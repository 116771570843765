import React, {useEffect, useState} from "react";
import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import Section from "./Section";
import CountUp from "react-countup";
import {axios, setAuthorization} from "../../helpers/axios";
import Swal from "sweetalert2";
import {useProfile} from "../../Components/Hooks/UserHooks";

const DashboardEcommerce = () => {
  document.title="Dashboard | Surveyor app";

  const [companies, setCompanies] = useState([]);
  const [products, setProducts] = useState([]);

  const [countSurvey, setCountSurvey] = useState(0);
  const [countProduct, setCountProduct] = useState(0);
  const [countUser, setCountUser] = useState(0);
  const [countCompanies, setCountCompanies] = useState(0);
  const { userProfile, loading, token } = useProfile();

  useEffect(() => {
    setAuthorization(token)
  }, []);


  useEffect(() => {
    const getCountsurvey = async () =>{
      await axios.get(process.env.REACT_APP_API_URL + `/v1/analytic/count/surveys`).then((resp) => {
        const data = resp.data
        setCountSurvey(data)
      }).catch((err) => {
        Swal.fire({
          title:"Oops",
          text:"Something error with our server",
          icon:"error",
        })
      })
    }

    const getCountProduct = async () =>{
      await axios.get(process.env.REACT_APP_API_URL + `/v1/analytic/count/products`).then((resp) => {
        const data = resp.data
        setCountProduct(data)
      }).catch((err) => {
        Swal.fire({
          title:"Oops",
          text:"Something error with our server",
          icon:"error",
        })
      })
    }

    const getCountCompanies= async () =>{
      await axios.get(process.env.REACT_APP_API_URL + `/v1/analytic/count/companies`).then((resp) => {
        const data = resp.data
        setCountCompanies(data)
      }).catch((err) => {
        Swal.fire({
          title:"Oops",
          text:"Something error with our server",
          icon:"error",
        })
      })
    }

    const getCountUsers = async () =>{
      await axios.get(process.env.REACT_APP_API_URL + `/v1/analytic/count/users`).then((resp) => {
        const data = resp.data
        setCountUser(data)
      }).catch((err) => {
        Swal.fire({
          title:"Oops",
          text:"Something error with our server",
          icon:"error",
        })
      })
    }

    const getTop10Companies = async () =>{
      await axios.get(process.env.REACT_APP_API_URL + `/v1/analytic/companies`).then((resp) => {
        const data = resp.data
        setCompanies(data)
      }).catch((err) => {
        Swal.fire({
          title:"Oops",
          text:"Something error with our server",
          icon:"error",
        })
      })
    }

    const getTop10Product = async () =>{
      await axios.get(process.env.REACT_APP_API_URL + `/v1/analytic/products`).then((resp) => {
        const data = resp.data
        setProducts(data)
      }).catch((err) => {
        Swal.fire({
          title:"Oops",
          text:"Something error with our server",
          icon:"error",
        })
      })
    }

    if (userProfile.role !== "ROLE_USER"){
      getTop10Companies()
      getTop10Product()
      getCountsurvey()
      getCountProduct()
      getCountCompanies()
      getCountUsers()
    }
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>      
          <Row>
            <Col>
              <div className="h-100">
                <Section />
                {userProfile.role !== "ROLE_USER" && (
                    <>
                      <Row>
                        <Col xl={3} md={6}>
                          <Card className={"card-animate bg-primary"}>
                            <CardBody>
                              <div className="d-flex align-items-center">
                                <div className="flex-grow-1 overflow-hidden">
                                  <p className="text-uppercase fw-bold text-white-50 text-truncate mb-0">Total survey</p>
                                </div>
                              </div>
                              <div className="d-flex align-items-end justify-content-between mt-4">
                                <div>
                                  <h4 className="fs-22 fw-bold ff-secondary mb-4 text-white"><span className="counter-value" data-target="559.25">
                                        <CountUp
                                            start={0}
                                            prefix={""}
                                            suffix={""}
                                            separator={","}
                                            end={countSurvey}
                                            decimals={0}
                                            duration={4}
                                        />
                                    </span></h4>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title rounded fs-3 bg-soft-light">
                              <i className={`text-white bx bx-check-circle`}></i>
                            </span>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>

                        <Col xl={3} md={6}>
                          <Card className={"card-animate bg-secondary"}>
                            <CardBody>
                              <div className="d-flex align-items-center">
                                <div className="flex-grow-1 overflow-hidden">
                                  <p className="text-uppercase fw-bold text-white-50 text-truncate mb-0">Total user</p>
                                </div>
                              </div>
                              <div className="d-flex align-items-end justify-content-between mt-4">
                                <div>
                                  <h4 className="fs-22 fw-bold ff-secondary mb-4 text-white"><span className="counter-value" data-target="559.25">
                                        <CountUp
                                            start={0}
                                            prefix={""}
                                            suffix={""}
                                            separator={","}
                                            end={countUser}
                                            decimals={0}
                                            duration={4}
                                        />
                                    </span></h4>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title rounded fs-3 bg-soft-light">
                              <i className={`text-white bx bx-user-circle`}></i>
                            </span>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>


                        <Col xl={3} md={6}>
                          <Card className={"card-animate bg-success"}>
                            <CardBody>
                              <div className="d-flex align-items-center">
                                <div className="flex-grow-1 overflow-hidden">
                                  <p className="text-uppercase fw-bold text-white-50 text-truncate mb-0">Total product</p>
                                </div>
                              </div>
                              <div className="d-flex align-items-end justify-content-between mt-4">
                                <div>
                                  <h4 className="fs-22 fw-bold ff-secondary mb-4 text-white"><span className="counter-value" data-target="559.25">
                                        <CountUp
                                            start={0}
                                            prefix={""}
                                            suffix={""}
                                            separator={","}
                                            end={countProduct}
                                            decimals={0}
                                            duration={4}
                                        />
                                    </span></h4>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title rounded fs-3 bg-soft-light">
                              <i className={`text-white bx bx-box`}></i>
                            </span>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>

                        <Col xl={3} md={6}>
                          <Card className={"card-animate bg-info"}>
                            <CardBody>
                              <div className="d-flex align-items-center">
                                <div className="flex-grow-1 overflow-hidden">
                                  <p className="text-uppercase fw-bold text-white-50 text-truncate mb-0">Total companies</p>
                                </div>
                              </div>
                              <div className="d-flex align-items-end justify-content-between mt-4">
                                <div>
                                  <h4 className="fs-22 fw-bold ff-secondary mb-4 text-white"><span className="counter-value" data-target="559.25">
                                        <CountUp
                                            start={0}
                                            prefix={""}
                                            suffix={""}
                                            separator={","}
                                            end={countCompanies}
                                            decimals={0}
                                            duration={4}
                                        />
                                    </span></h4>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title rounded fs-3 bg-soft-light">
                              <i className={`text-white bx bx-building`}></i>
                            </span>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        {/*<Widget />*/}
                      </Row>
                      <Row>
                        <Col>
                          <Card>
                            <CardHeader>
                              Top 10 company
                            </CardHeader>
                            <CardBody>
                              <table className={"table table-striped table-bordered"}>
                                <thead>
                                <tr>
                                  <th>No</th>
                                  <td>Nama</td>
                                  <td>Jumlah</td>
                                </tr>
                                </thead>
                                <tbody>
                                {companies.map((row,index) => {
                                  return (
                                      <tr key={row.id}>
                                        <th>{index +1}</th>
                                        <td>{row.name}</td>
                                        <td>{row.count}</td>
                                      </tr>
                                  )
                                })}

                                </tbody>
                              </table>
                            </CardBody>
                          </Card>
                        </Col>


                        <Col>
                          <Card>
                            <CardHeader>
                              Top 10 product
                            </CardHeader>
                            <CardBody>
                              <table className={"table table-striped table-bordered"}>
                                <thead>
                                <tr>
                                  <th>No</th>
                                  <td>Nama</td>
                                  <td>Jumlah</td>
                                </tr>
                                </thead>
                                <tbody>
                                {products.map((row,index) => {
                                  return (
                                      <tr key={row.id}>
                                        <th>{index +1}</th>
                                        <td>{row.name}</td>
                                        <td>{row.count}</td>
                                      </tr>
                                  )
                                })}
                                </tbody>
                              </table>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </>
                )}
                {/*<Row>*/}
                {/*  <Col xl={8}>*/}
                {/*    <Revenue />*/}
                {/*  </Col>*/}
                {/*  <SalesByLocations />*/}
                {/*</Row>*/}
                {/*<Row>*/}
                {/*  <BestSellingProducts />*/}
                {/*  <TopSellers />*/}
                {/*</Row>*/}
                {/*<Row>*/}
                {/*  <StoreVisits />*/}
                {/*  <RecentOrders />*/}
                {/*</Row>*/}
              </div>
            </Col>
            {/*<RecentActivity />*/}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardEcommerce;
