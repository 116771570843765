import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
    const history = useNavigate();
    //state data
    const [isDashboard, setIsDashboard] = useState(false);
    const [isApps, setIsApps] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [isPages, setIsPages] = useState(false);
    const [isBaseUi, setIsBaseUi] = useState(false);
    const [isAdvanceUi, setIsAdvanceUi] = useState(false);
    const [isForms, setIsForms] = useState(false);
    const [isTables, setIsTables] = useState(false);
    const [isCharts, setIsCharts] = useState(false);
    const [isIcons, setIsIcons] = useState(false);
    const [isMaps, setIsMaps] = useState(false);
    const [isMultiLevel, setIsMultiLevel] = useState(false);

    // Apps
    const [isEmail, setEmail] = useState(false);
    const [isSubEmail, setSubEmail] = useState(false);
    const [isEcommerce, setIsEcommerce] = useState(false);
    const [isProjects, setIsProjects] = useState(false);
    const [isTasks, setIsTasks] = useState(false);
    const [isCRM, setIsCRM] = useState(false);
    const [isCrypto, setIsCrypto] = useState(false);
    const [isInvoices, setIsInvoices] = useState(false);
    const [isSupportTickets, setIsSupportTickets] = useState(false);
    const [isNFTMarketplace, setIsNFTMarketplace] = useState(false);
    const [isLanding, setIsLanding] = useState(false);
    const [isJobs, setIsJobs] = useState(false);
    const [isJobList, setIsJobList] = useState(false);
    const [isCandidateList, setIsCandidateList] = useState(false);

    // Authentication
    const [isSignIn, setIsSignIn] = useState(false);
    const [isSignUp, setIsSignUp] = useState(false);
    const [isPasswordReset, setIsPasswordReset] = useState(false);
    const [isPasswordCreate, setIsPasswordCreate] = useState(false);
    const [isLockScreen, setIsLockScreen] = useState(false);
    const [isLogout, setIsLogout] = useState(false);
    const [isSuccessMessage, setIsSuccessMessage] = useState(false);
    const [isVerification, setIsVerification] = useState(false);
    const [isError, setIsError] = useState(false);

    // Pages
    const [isProfile, setIsProfile] = useState(false);

    // Charts
    const [isApex, setIsApex] = useState(false);

    // Multi Level
    const [isLevel1, setIsLevel1] = useState(false);
    const [isLevel2, setIsLevel2] = useState(false);

    const [iscurrentState, setIscurrentState] = useState('Dashboard');

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'Dashboard') {
            setIsDashboard(false);
        }
        if (iscurrentState !== 'Apps') {
            setIsApps(false);
        }
        if (iscurrentState !== 'Auth') {
            setIsAuth(false);
        }
        if (iscurrentState !== 'Pages') {
            setIsPages(false);
        }
        if (iscurrentState !== 'Landing') {
            setIsLanding(false);
        }
        if (iscurrentState !== 'BaseUi') {
            setIsBaseUi(false);
        }
        if (iscurrentState !== 'AdvanceUi') {
            setIsAdvanceUi(false);
        }
        if (iscurrentState !== 'Forms') {
            setIsForms(false);
        }
        if (iscurrentState !== 'Tables') {
            setIsTables(false);
        }
        if (iscurrentState !== 'Charts') {
            setIsCharts(false);
        }
        if (iscurrentState !== 'Icons') {
            setIsIcons(false);
        }
        if (iscurrentState !== 'Maps') {
            setIsMaps(false);
        }
        if (iscurrentState !== 'MuliLevel') {
            setIsMultiLevel(false);
        }
        if (iscurrentState === 'Widgets') {
            history("/widgets");
            document.body.classList.add('twocolumn-panel');
        }
    }, [
        history,
        iscurrentState,
        isDashboard,
        isApps,
        isAuth,
        isPages,
        isBaseUi,
        isAdvanceUi,
        isForms,
        isTables,
        isCharts,
        isIcons,
        isMaps,
        isMultiLevel
    ]);

    const menuItems = [
        {
            label: "Menu",
            isHeader: true,
        },
        {
            id: "dashboard",
            label: "Dashboard",
            roles:["ROLE_SYSTEM","ROLE_USER"],
            icon: "ri-dashboard-2-line",
            link: "/dashboard",
            isChildItem: false,
            stateVariables: isDashboard,
        },
        {
            id: "product",
            roles:["ROLE_SYSTEM"],
            icon:"ri-folder-line",
            label: "Product",
            link: "/products",
            parentId: "dashboard",
        },
        {
            id: "company",
            roles:["ROLE_SYSTEM"],
            icon: "ri-database-line",
            label: "Company",
            link: "/companies",
            parentId: "dashboard",
        },
        {
            id: "category_1",
            roles:["ROLE_SYSTEM"],
            label: "Lighting",
            icon:"ri-lightbulb-line",
            link: "/lighting",
            parentId: "dashboard",
        },
        {
            id: "category_2",
            roles:["ROLE_SYSTEM"],
            label: "Unit 3d",
            icon: "ri-inbox-line",
            link: "/unit3d",
            parentId: "dashboard",
        },
        {
            id: "survey",
            roles:["ROLE_SYSTEM", "ROLE_USER"],
            label: "Survey",
            icon:"ri-article-line",
            link: "/survey",
            parentId: "apps",
        },
        {
            id: "brand",
            roles:["ROLE_SYSTEM"],
            label: "Brand",
            icon:"ri-stack-line",
            link: "/brands",
            parentId: "apps",
        },
        {
            id: "promo_unit",
            roles:["ROLE_SYSTEM"],
            label: "Promo unit",
            icon: "ri-gift-line",
            link: "/promo",
            parentId: "apps",
        },
        {
            id: "measurement",
            roles:["ROLE_SYSTEM"],
            label: "Measurement",
            icon: "ri-ruler-line",
            link: "/measurements",
            parentId: "apps",
        },
        {
            id: "user",
            roles:["ROLE_SYSTEM"],
            label: "User",
            icon: "ri-user-line",
            link: "/users",
            parentId: "apps",
        },
        {
            id: "config",
            roles:["ROLE_SYSTEM"],
            label: "Web config",
            icon: "ri-file-settings-line",
            link: "/config",
            parentId: "apps",
        },
    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;