import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import moment from "moment-timezone";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useProfile } from "../../../Components/Hooks/UserHooks";
import { axios } from "../../../helpers/axios";

const ExpandTableRow = ({ data }) => {
  return (
    <>
      <Row className="my-5">
        <Col>
          <table>
            <tbody>
              <tr>
                <td>No</td>
                <td>:</td>
                <td>{data.document_no}</td>
              </tr>
              <tr>
                <td>Pencahayaan</td>
                <td>:</td>
                <td>{data.category_1_id}</td>
              </tr>
              <tr>
                <td>Unit 3D</td>
                <td>:</td>
                <td>{data.category_2_id}</td>
              </tr>
              <tr>
                <td>Product</td>
                <td>:</td>
                <td>{data.product_name}</td>
              </tr>
              <tr>
                <td>Company</td>
                <td>:</td>
                <td>{data.company_name}</td>
              </tr>
              <tr>
                <td>Brand</td>
                <td>:</td>
                <td>{data.brand_name}</td>
              </tr>
              <tr>
                <td>Unit Promo</td>
                <td>:</td>
                <td>{data.promo_name}</td>
              </tr>
              <tr>
                <td>Measurement</td>
                <td>:</td>
                <td>{data.measurement_name}</td>
              </tr>
              <tr>
                <td>Province</td>
                <td>:</td>
                <td>{data.province}</td>
              </tr>
              <tr>
                <td>Regency</td>
                <td>:</td>
                <td>{data.regency}</td>
              </tr>
              <tr>
                <td>District</td>
                <td>:</td>
                <td>{data.district}</td>
              </tr>
              <tr>
                <td>Postal code</td>
                <td>:</td>
                <td>{data.postal_code}</td>
              </tr>
              <tr>
                <td>Location</td>
                <td>:</td>
                <td>{data.location}</td>
              </tr>
              <tr>
                <td>Address</td>
                <td>:</td>
                <td>{data.generate_address}</td>
              </tr>
              <tr>
                <td>Created</td>
                <td>:</td>
                <td>{data.created}</td>
              </tr>
              <tr>
                <td>Action</td>
                <td>:</td>
                <td>
                  <a
                    className={"btn btn-sm btn-primary"}
                    rel={"noreferrer"}
                    href={data.location_url}
                    target={"_blank"}
                  >
                    Buka maps
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
        <Col>
          <a href={data.image} rel={"noreferrer"} target={"_blank"}>
            <img src={data.image} width={"50%"} alt={""} />
          </a>
        </Col>
      </Row>
    </>
  );
};

const radius = 20;

const Survey = (props) => {
  document.title = "Survey | Surveyor app";
  const [modal_list, setmodal_list] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Data, setData] = useState([]);
  const [Paginator, setPaginator] = useState(null);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [exportDataModal, setExportDataModal] = useState(false);
  const [exportFileModal, setExportFileModal] = useState(false);

  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");
  const [userId, setUserId] = useState("");

  const [users, setUsers] = useState([]);

  const { userProfile } = useProfile();

  let navigate = useNavigate();

  useEffect(() => {
    fetchSurvey(pageSize, page, search);
  }, [page, pageSize, search]);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  useEffect(() => {
    const fetchUser = async () => {
      const response = await axios
        .get(process.env.REACT_APP_API_URL + `/v1/users_pluck`)
        .catch((err) => {
          Swal.fire({
            title: "Oops",
            text: "Something error with our server",
            icon: "error",
          });
        });
      setUsers(response.data);
    };

    if (userProfile.role === "ROLE_SYSTEM") {
      fetchUser();
    }
  }, []);

  const editSurvey = (id) => {
    console.log(id);
  };

  const deleteSurvey = (id) => {
    Swal.fire({
      text: "Are you sure delete this record ?",
      icon: "info",
      showCancelButton: true,
    }).then((ok) => {
      if (ok.isConfirmed) {
        //delete
        axios
          .delete(process.env.REACT_APP_API_URL + `/v1/survey/${id}`)
          .then((resp) => {
            fetchSurvey(pageSize, page, search);
          })
          .catch((err) => {
            Swal.fire({
              title: "Oops",
              text: "Something error with our server",
              icon: "error",
            });
          });
      }
    });
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">No.</span>,
      selector: (row, index) => index + 1,
    },
    {
      name: <span className="font-weight-bold fs-13">Document no</span>,
      selector: (row) => row.document_no,
    },
    {
      name: <span className="font-weight-bold fs-13">Product name</span>,
      selector: (row) => row.product_name,
    },
    {
      name: <span className="font-weight-bold fs-13">Promo name</span>,
      selector: (row) => row.promo_name,
    },
    {
      name: <span className="font-weight-bold fs-13">Measurement</span>,
      selector: (row) => row.measurement_name,
    },
    {
      name: <span className="font-weight-bold fs-13">Company</span>,
      selector: (row) => row.company_name,
    },
    {
      name: <span className="font-weight-bold fs-13">Created by</span>,
      selector: (row) => row.created_by,
    },
    {
      name: <span className="font-weight-bold fs-13">Address</span>,
      selector: (row) => row.generate_address,
    },
    {
      name: <span className="font-weight-bold fs-13">Lat,long</span>,
      selector: (row) => row.location,
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      cell: (cell) => {
        return (
          <>
            {/*<button className="btn btn-sm btn-soft-info me-2" onClick={() => editSurvey(cell.id)}>Edit</button>*/}
            <button
              className="btn btn-sm btn-soft-danger"
              onClick={() => deleteSurvey(cell.id)}
            >
              Delete
            </button>
          </>
        );
      },
    },
  ];

  const fetchSurvey = (pageSize, page, search) => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          `/v1/survey/paginated?limit=${pageSize}&page=${page}&order=desc&sort_by=id&search=${search}`
      )
      .then((resp) => {
        const data = resp.data;
        setData(data.items);
        setPaginator(data.paginator);
      })
      .catch((err) => {
        Swal.fire({
          title: "Oops",
          text: "Something error with our server",
          icon: "error",
        });
      });
  };
  function ShowCreateForm() {
    navigate("/survey/create");
    setmodal_list(!modal_list);
  }

  const ShowExportData = () => {
    setLoading(false);
    setDateStart("");
    setDateEnd("");
    setUserId("");
    setExportDataModal(!exportDataModal);
  };

  const ShowExportFile = () => {
    setLoading(false);
    setDateStart("");
    setDateEnd("");
    setUserId("");
    setExportFileModal(!exportFileModal);
  };

  const exportFileSurvey = () => {
    setLoading(true);
    const start = moment(dateStart).unix();
    const end = moment(dateEnd).unix();
    axios
      .get(
        process.env.REACT_APP_API_URL +
          `/v1/survey/exportfile?date_start=${start}&date_end=${end}&user_id=${userId}&is_export_all=${
            userId === "" ? "1" : "0"
          }`,
        {
          responseType: "blob",
        }
      )
      .then((resp) => {
        const href = URL.createObjectURL(resp);
        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "export-file.zip"); //or any other extension
        document.body.appendChild(link);
        link.click();
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        ShowExportFile();
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: "Oops",
          text: "Something error with our server",
          icon: "error",
        });
      });
  };

  const exportDataSurvey = () => {
    setLoading(true);
    const start = moment(dateStart).unix();
    const end = moment(dateEnd).unix();
    axios
      .get(
        process.env.REACT_APP_API_URL +
          `/v1/survey/export?date_start=${start}&date_end=${end}&user_id=${userId}&is_export_all=${
            userId === "" ? "1" : "0"
          }`,
        {
          responseType: "blob",
        }
      )
      .then((resp) => {
        const href = URL.createObjectURL(resp);
        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "export-data.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        ShowExportData();
      })
      .catch((err) => {
        Swal.fire({
          title: "Oops",
          text: "Something error with our server",
          icon: "error",
        });
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Survey" pageTitle="Survey" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="align-items-center">
                    <Col>
                      <h4 className="card-title mb-0 flex-grow-1">
                        Data survey
                      </h4>
                    </Col>

                    <Col className="col-sm-auto">
                      <div className="d-flex flex-row mt-2 gap-2 col-12">
                        {userProfile.role === "ROLE_SYSTEM" && (
                          <>
                            <Button
                              onClick={() => ShowExportFile()}
                              color="primary"
                              className="add-btn"
                              id="create-btn"
                            >
                              <i className="align-bottom me-1"></i> Export file
                            </Button>
                            <Button
                              onClick={() => ShowExportData()}
                              color="primary"
                              className="add-btn"
                              id="create-btn"
                            >
                              <i className="align-bottom me-1"></i> Export data
                            </Button>
                          </>
                        )}
                        <Button
                          onClick={() => ShowCreateForm()}
                          color="success"
                          className="add-btn"
                          id="create-btn"
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          Survey
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <DataTable
                    expandableRows={true}
                    expandableRowsComponent={ExpandTableRow}
                    columns={columns}
                    data={Data}
                    pagination
                    paginationServer={true}
                    paginationTotalRows={
                      Paginator !== null ? Paginator.total_item : 0
                    }
                    onChangePage={(page) => setPage(page)}
                    onChangeRowsPerPage={(e) => setPageSize(e)}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* export data */}
          <Modal
            isOpen={exportDataModal}
            toggle={() => {
              ShowExportData();
            }}
            centered
          >
            <ModalHeader
              className="bg-light p-3"
              id="exampleModalLabel"
              toggle={() => {
                ShowExportData();
              }}
            >
              {" "}
              Download data survey{" "}
            </ModalHeader>
            <ModalBody>
              {loading && (
                <div className="alert alert-primary" role="alert">
                  Wait for a while, system collecting data
                </div>
              )}
              <div className="mb-3">
                <Label htmlFor="date_start" className="form-label">
                  Date start
                </Label>
                <Input
                  value={dateStart}
                  onChange={(e) => setDateStart(e.target.value)}
                  name="date_start"
                  className="form-control"
                  placeholder="Enter date start"
                  type="date"
                />
              </div>
              <div className="mb-3">
                <Label htmlFor="date_end" className="form-label">
                  Date end
                </Label>
                <Input
                  value={dateEnd}
                  onChange={(e) => setDateEnd(e.target.value)}
                  name="date_end"
                  className="form-control"
                  placeholder="Enter date end"
                  type="date"
                />
              </div>

              <div className="position-relative auth-pass-inputgroup mb-3">
                <Label className="form-label" htmlFor="user_id">
                  Users
                </Label>
                <select
                  className="form-select"
                  aria-label="select example"
                  id="user_id"
                  name="user_id"
                  onChange={(e) => setUserId(e.target.value)}
                  value={userId}
                >
                  <option value="">All user</option>
                  {users.map((row) => {
                    return (
                      <option key={row.id} value={row.id}>
                        {row.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="hstack gap-2 justify-content-end">
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={() => setExportDataModal(false)}
                >
                  Close
                </button>
                <button
                  disabled={loading}
                  type="submit"
                  className="btn btn-success"
                  onClick={exportDataSurvey}
                  id="add-btn"
                >
                  Download
                </button>
              </div>
            </ModalFooter>
          </Modal>

          {/* export file */}
          <Modal
            isOpen={exportFileModal}
            toggle={() => {
              ShowExportFile();
            }}
            centered
          >
            <ModalHeader
              className="bg-light p-3"
              id="exampleModalLabel"
              toggle={() => {
                ShowExportFile();
              }}
            >
              {" "}
              Download file survey{" "}
            </ModalHeader>
            <ModalBody>
              {loading && (
                <div className="alert alert-primary" role="alert">
                  Wait for a while, system collecting data
                </div>
              )}
              <div className="mb-3">
                <Label htmlFor="date_start" className="form-label">
                  Date start
                </Label>
                <Input
                  value={dateStart}
                  onChange={(e) => setDateStart(e.target.value)}
                  name="date_start"
                  className="form-control"
                  placeholder="Enter date start"
                  type="date"
                />
              </div>
              <div className="mb-3">
                <Label htmlFor="date_end" className="form-label">
                  Date end
                </Label>
                <Input
                  value={dateEnd}
                  onChange={(e) => setDateEnd(e.target.value)}
                  name="date_end"
                  className="form-control"
                  placeholder="Enter date end"
                  type="date"
                />
              </div>

              <div className="position-relative auth-pass-inputgroup mb-3">
                <Label className="form-label" htmlFor="user_id">
                  Users
                </Label>
                <select
                  className="form-select"
                  aria-label="select example"
                  id="user_id"
                  name="user_id"
                  onChange={(e) => setUserId(e.target.value)}
                  value={userId}
                >
                  <option value="">- All user -</option>
                  {users.map((row) => {
                    return (
                      <option key={row.id} value={row.id}>
                        {row.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="hstack gap-2 justify-content-end">
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={() => setExportDataModal(false)}
                >
                  Close
                </button>
                <button
                  disabled={loading}
                  type="submit"
                  className="btn btn-success"
                  onClick={exportFileSurvey}
                  id="add-btn"
                >
                  Download
                </button>
              </div>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Survey;
