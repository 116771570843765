import React, {useEffect, Measurementef, useState} from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter, Label, Input, FormFeedback, CardFooter
} from 'reactstrap';

import BreadCrumb from '../../../Components/Common/BreadCrumb';
import {useFormik} from "formik";
import * as Yup from "yup";
import DataTable from "react-data-table-component";
import {axios} from "../../../helpers/axios";
import Swal from "sweetalert2";

const WebConfig = (props) => {
    document.title="Website config | Surveyor app";
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            time_start:  '',
            time_end: '',
        },
        validationSchema: Yup.object({
            time_start: Yup.string().required("Please Enter Time Start"),
            time_end: Yup.string().required("Please Enter Time End"),
        }),
        onSubmit: async (values) => {
            const payload = {
                time_start:values.time_start,
                time_end:values.time_end,
            }
            const {data} = await axios.put(process.env.REACT_APP_API_URL + `/v1/config`, payload)
            if (data === "ok"){
                Swal.fire({
                    title:"Success",
                    text:"Success update config",
                    icon:"success",
                }).then((resp) => {
                    fetchConfig()
                })
            }
        }
    });

    const fetchConfig = async () => {
        const {data} = await axios.get(process.env.REACT_APP_API_URL + `/v1/config`).catch((err) => {
            Swal.fire({
                title:"Oops",
                text:"Something error with our server",
                icon:"error",
            })
        })

        await validation.setValues({
            time_start: data.time_start,
            time_end: data.time_end
        })
    }

    useEffect( () => {
        fetchConfig()
    },[])


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Website Config" pageTitle="Config" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <Row className="align-items-center">
                                        <Col>
                                            <h4 className="card-title mb-0 flex-grow-1">Website config</h4>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <form onSubmit={validation.handleSubmit}>
                                    <CardBody>
                                        <Row>
                                            <Col lg={6} sm={12}>
                                                <Label>Time start</Label>
                                                <Input
                                                    value={validation.values.time_start}
                                                    onChange={validation.handleChange}
                                                    name="time_start"
                                                    className="form-control"
                                                    placeholder="Enter time start"
                                                    type="time"
                                                />
                                            </Col>
                                            <Col lg={6} sm={12}>
                                                <Label>Time end</Label>
                                                <Input
                                                    value={validation.values.time_end}
                                                    onChange={validation.handleChange}
                                                    name="time_end"
                                                    className="form-control"
                                                    placeholder="Enter time end"
                                                    type="time"
                                                />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter className={"justify-content-end d-flex"}>
                                        <Button color={"primary"}>Submit</Button>
                                    </CardFooter>
                                </form>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default WebConfig




