import axios from "axios";
import {api} from "../config";

axios.defaults.baseURL = api.API_URL;
// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

// content type
const token = JSON.parse(localStorage.getItem("authUser")) ? JSON.parse(localStorage.getItem("authUser")).accessToken : null;
if(token){
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}

const setAuthorization = (token) => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}

export  {axios, setAuthorization};