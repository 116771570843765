import React, {useEffect, Measurementef, useState} from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter, Label, Input, FormFeedback
} from 'reactstrap';

import BreadCrumb from '../../../Components/Common/BreadCrumb';
import {useFormik} from "formik";
import * as Yup from "yup";
import DataTable from "react-data-table-component";
import {axios} from "../../../helpers/axios";
import Swal from "sweetalert2";
import Select from "react-select";

const Promo = (props) => {
    document.title="Promo | Surveyor app";
    const [modal_list, setmodal_list] = useState(false);
    const [promoData, setPromoData] = useState([]);
    const [Data, setData] = useState([]);
    const [Paginator, setPaginator] = useState(null);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState('');
    const [measurementData, setMeasurementData] = useState([]);

    const columns = [
        {
            name: <span className='font-weight-bold fs-13'>No.</span>,
            selector: (row, index) => index +1,
        },
        {
            name: <span className='font-weight-bold fs-13'>Name</span>,
            selector: row => row.name,
        },
        {
            name: <span className='font-weight-bold fs-13'>Description</span>,
            selector: row => row.description,
        },
        {
            name: <span className='font-weight-bold fs-13'>Action</span>,
            cell: (cell) => {
                return (
                    <>
                        <button className="btn btn-sm btn-soft-info me-2" onClick={() => editPromo(cell.id)}>Edit</button>
                        <button className="btn btn-sm btn-soft-danger" onClick={() => onDelete(cell.id)}>Delete</button>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        fetchAllMeasurement()
    }, []);

    useEffect(() => {
        fetchPromo(pageSize, page, search)
    }, [page, pageSize, search]);

    useEffect(() => {
        setPage(1);
    }, [pageSize]);

    const fetchAllMeasurement = () => {
        axios.get(process.env.REACT_APP_API_URL + `/v1/measurements`).then((resp) => {
            const data = resp.data
            setMeasurementData(data)
        }).catch((err) => {
            Swal.fire({
                title:"Oops",
                text:"Something error with our server",
                icon:"error",
            })
        })
    }
    const fetchPromo = (pageSize, page, search) => {
        axios.get(process.env.REACT_APP_API_URL + `/v1/promo/paginated?limit=${pageSize}&page=${page}&order=desc&sort_by=id&search=${search}`).then((resp) => {
            const data = resp.data
            setData(data.items)
            setPaginator(data.paginator)
        }).catch((err) => {
            Swal.fire({
                title:"Oops",
                text:"Something error with our server",
                icon:"error",
            })
        })
    }

    const onDelete = (id) => {
        Swal.fire({
            title:"Confirmation",
            text:"Are you sure delete this record ?",
            icon:"info",
            showCancelButton:true,
        }).then((ok) => {
            if (ok.isConfirmed){
                //delete
                axios.delete(process.env.REACT_APP_API_URL + `/v1/promo/${id}`).then((resp) => {
                    fetchPromo(pageSize, page, search)
                }).catch((err) => {
                    Swal.fire({
                        title:"Oops",
                        text:"Something error with our server",
                        icon:"error",
                    })
                })
            }
        })
    }

    const editPromo = async (id) => {
        const {data} = await axios.get(process.env.REACT_APP_API_URL + `/v1/promo/${id}`).catch((err) => {
            Swal.fire({
                title:"Oops",
                text:"Something error with our server",
                icon:"error",
            })
        })
        await validation.setValues({
            id: id,
            name: data.name,
            code: data.code,
            description: data.description,
            measurements: data.measurements,
            category_2_id: data.category_2_id,
        })
        ShowCloseModal()
    }

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            id: promoData.id || '',
            name: promoData.name || '',
            code: promoData.code || '',
            description: promoData.description || '',
            measurements: promoData.measurements || [],
        },
        validationSchema: Yup.object({
            id: Yup.string().nullable(),
            name: Yup.string().required("Please Enter name"),
            description: Yup.string().required("Please Enter description"),
            measurements: Yup.array().required("Please select measurement"),
        }),
        onSubmit: async (values) => {
            console.log(values)
            const payload = {
                name:values.name,
                code:values.code,
                desc: values.description,
                measurements:values.measurements.map((row) => row.value)
            }
            if (values.id === undefined || values.id === ""){
                const {data} = await axios.post(process.env.REACT_APP_API_URL + `/v1/promo`, payload)
                if (data === "ok"){
                    Swal.fire({
                        title:"Success",
                        text:"Success create product",
                        icon:"success",
                    }).then((resp) => {
                        fetchPromo(pageSize, page, search)
                    })
                }
            }else{
                const {data} = await axios.put(process.env.REACT_APP_API_URL + `/v1/promo/${values.id}`, payload)
                if (data === "ok"){
                    Swal.fire({
                        title:"Success",
                        text:"Success update product",
                        icon:"success",
                    }).then((resp) => {
                        fetchPromo(pageSize, page, search)
                    })
                }
            }
            ShowCloseModal()
        }
    });

    const defaultValue = measurementData.filter((row) => validation.values.measurements.includes(row.id)).map((filtered) => {
        return {
            value: filtered.id,
            label:filtered.name
        }
    })
    function ShowCloseModal() {
        setmodal_list(!modal_list);
    }
    function ShowCreateForm() {
        validation.resetForm()
        setmodal_list(!modal_list);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Promo" pageTitle="Promo" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <Row className="align-items-center">
                                        <Col>
                                            <h4 className="card-title mb-0 flex-grow-1">Data promo</h4>
                                        </Col>

                                        <Col className="col-sm-auto">
                                            <div className="d-flex gap-1">
                                                <Button onClick={() => ShowCreateForm()} color="success" className="add-btn" id="create-btn"><i className="ri-add-line align-bottom me-1"></i> Add Promo</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardHeader>

                                <CardBody>
                                    <DataTable
                                        columns={columns}
                                        data={Data}
                                        pagination
                                        paginationServer={true}
                                        paginationTotalRows={Paginator !== null ? Paginator.total_item : 0}
                                        onChangePage={(page) => setPage(page)}
                                        onChangeRowsPerPage={(e) => setPageSize(e)}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal isOpen={modal_list} toggle={() => { ShowCreateForm(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { ShowCreateForm(); }}>{validation.values.id ? "Edit" : "Add"} Promo </ModalHeader>
                <form  className="tablelist-form wa" onSubmit={validation.handleSubmit}>
                    <ModalBody>
                        <div className="mb-3">
                            <Label htmlFor="code" className="form-label">Kode promo</Label>
                            <Input
                                name="code"
                                className="form-control"
                                placeholder="Enter kode promo"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.code || ""}
                                invalid={
                                    validation.touched.code && validation.errors.code ? true : false
                                }
                            />
                            {validation.touched.code && validation.errors.code ? (
                                <div className="text-danger text-sm">{validation.errors.code}</div>
                            ) : null}
                        </div>

                        <div className="mb-3">
                            <Label htmlFor="name" className="form-label">Name</Label>
                            <Input
                                name="name"
                                className="form-control"
                                placeholder="Enter name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                    validation.touched.name && validation.errors.name ? true : false
                                }
                            />
                            {validation.touched.name && validation.errors.name ? (
                                <div className="text-danger text-sm">{validation.errors.name}</div>
                            ) : null}
                        </div>


                        <div className="mb-3">
                            <Label htmlFor="description" className="form-label">Description</Label>
                            <Input
                                name="description"
                                className="form-control"
                                placeholder="Enter description"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.description || ""}
                                invalid={
                                    validation.touched.description && validation.errors.description ? true : false
                                }
                            />
                            {validation.touched.description && validation.errors.description ? (
                                <div className="text-danger text-sm">{validation.errors.description}</div>
                            ) : null}
                        </div>

                        <div className="position-relative mb-3">
                            <Label className="form-label" htmlFor="category_2_id">Measurement</Label>
                            <Select
                                defaultValue={defaultValue}
                                isMulti
                                name="colors"
                                onBlur={validation.handleBlur}
                                onChange={(e) => validation.setFieldValue("measurements", e)}
                                options={measurementData.map((row) => {
                                    return {
                                        value: row.id,
                                        label:row.name
                                    }
                                })}
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />

                            {validation.touched.measurements && validation.errors.measurements ? (
                                <div className="text-danger text-sm">{validation.errors.measurements}</div>
                            ) : null}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Close</button>
                            <button type="submit" className="btn btn-success" id="add-btn">{validation.values.id ? "Edit" : "Add"} Promo</button>
                        </div>
                    </ModalFooter>
                </form>
            </Modal>
        </React.Fragment>
    );
}

export default Promo




