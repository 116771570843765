import React, {useEffect, useRef, useState} from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter, Label, Input, FormFeedback
} from 'reactstrap';

import BreadCrumb from '../../../Components/Common/BreadCrumb';
import {useFormik} from "formik";
import * as Yup from "yup";
import DataTable from "react-data-table-component";
import {axios} from "../../../helpers/axios";
import Swal from "sweetalert2";

const User = (props) => {
    document.title="Users | Surveyor app";
    const [modal_list, setmodal_list] = useState(false);
    const [userData, setUserData] = useState([]);
    const [passwordShow, setPasswordShow] = useState(false);
    const [Data, setData] = useState([]);
    const [Paginator, setPaginator] = useState(null);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState('');

    const columns = [
        {
            name: <span className='font-weight-bold fs-13'>No.</span>,
            selector: (row, index) => index +1,
        },
        {
            name: <span className='font-weight-bold fs-13'>Name</span>,
            selector: row => row.name,
        },
        {
            name: <span className='font-weight-bold fs-13'>Email</span>,
            selector: row => row.email,
        },
        {
            name: <span className='font-weight-bold fs-13'>Username</span>,
            selector: row => row.username,
        },
        {
            name: <span className='font-weight-bold fs-13'>Phone</span>,
            selector: row => '+62' + row.phone,
        },
        {
            name: <span className='font-weight-bold fs-13'>Role</span>,
            selector: row => row.role,
        },
        {
            name: <span className='font-weight-bold fs-13'>Action</span>,
            cell: (cell) => {
                return (
                    <>
                        <button className="btn btn-sm btn-soft-info me-2" onClick={() => editUser(cell.id)}>Edit</button>
                        <button className="btn btn-sm btn-soft-danger" onClick={() => onDelete(cell.id)}>Delete</button>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        fetchUser(pageSize, page, search)
    }, [page, pageSize, search]);

    useEffect(() => {
        setPage(1);
    }, [pageSize]);

    const fetchUser = (pageSize, page, search) => {
        axios.get(process.env.REACT_APP_API_URL + `/v1/users?limit=${pageSize}&page=${page}&order=desc&sort_by=id&search=${search}`).then((resp) => {
            const data = resp.data
            setData(data.items)
            setPaginator(data.paginator)
        }).catch((err) => {
            Swal.fire({
                title:"Oops",
                text:"Something error with our server",
                icon:"error",
            })
        })
    }

    const onDelete = (id) => {
        Swal.fire({
            title:"Confirmation",
            text:"Are you sure delete this record ?",
            icon:"info",
            showCancelButton:true,
        }).then((ok) => {
            if (ok.isConfirmed){
                //delete
                axios.delete(process.env.REACT_APP_API_URL + `/v1/users/${id}`).then((resp) => {
                    fetchUser(pageSize, page, search)
                }).catch((err) => {
                    Swal.fire({
                        title:"Oops",
                        text:"Something error with our server",
                        icon:"error",
                    })
                })
            }
        })
    }


    const editUser = async (id) => {
        const {data} = await axios.get(process.env.REACT_APP_API_URL + `/v1/users/${id}`).catch((err) => {
            Swal.fire({
                title:"Oops",
                text:"Something error with our server",
                icon:"error",
            })
        })
        await validation.setValues({
            id: id,
            name: data.name,
            password: "",
            phone: data.phone,
            email: data.email,
            role: data.role,
            profile: "",
            username: data.username
        })

        ShowCloseModal()
    }

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            id: userData.id || '',
            username: userData.username || '',
            password: userData.password || '',
            name: userData.name || '',
            phone: userData.phone || '',
            email: userData.email || '',
            profile: userData.profile || '',
            role: userData.role || '',
        },
        validationSchema: Yup.object({
            id: Yup.string().nullable(),
            name: Yup.string().required("Please Enter Your name"),
            phone: Yup.string().required("Please Enter Your phone"),
            email: Yup.string().email('Enter valid email').required("Please Enter Your email"),
            username: Yup.string().required("Please Enter Your username"),
            password: Yup.string().when('id', (val, schema) => {
                if (val === undefined){
                    return schema.required("Please Enter Your Password")
                }
               return schema
            }),
            role: Yup.string().required("Please Select Role"),
        }),
        onSubmit: async (values) => {
            const payload = {
                    name:values.name,
                    username:values.username,
                    password:values.password,
                    image:values.image,
                    role:values.role,
                    email:values.email,
                    phone:values.phone.toString(),
            }
            if (values.id === undefined || values.id === ""){
                const {data} = await axios.post(process.env.REACT_APP_API_URL + `/v1/users`, payload)
                if (data === "ok"){
                    Swal.fire({
                        title:"Success",
                        text:"Success create user",
                        icon:"success",
                    }).then((resp) => {
                        fetchUser(pageSize, page, search)
                    })
                }
            }else{
                const {data} = await axios.put(process.env.REACT_APP_API_URL + `/v1/users/${values.id}`, payload)
                if (data === "ok"){
                    Swal.fire({
                        title:"Success",
                        text:"Success update user",
                        icon:"success",
                    }).then((resp) => {
                        fetchUser(pageSize, page, search)
                    })
                }
            }
            ShowCloseModal()
        }
    });

    function ShowCloseModal() {
        setmodal_list(!modal_list);
    }
    function ShowCreateForm() {
        validation.resetForm()
        setmodal_list(!modal_list);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Users" pageTitle="Users" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <Row className="align-items-center">
                                        <Col>
                                            <h4 className="card-title mb-0 flex-grow-1">Data users</h4>
                                        </Col>

                                        <Col className="col-sm-auto">
                                            <div className="d-flex gap-1">
                                                <Button onClick={() => ShowCreateForm()} color="success" className="add-btn" id="create-btn"><i className="ri-add-line align-bottom me-1"></i> Add</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardHeader>

                                <CardBody>
                                    <DataTable
                                        columns={columns}
                                        data={Data}
                                        pagination
                                        paginationServer={true}
                                        paginationTotalRows={Paginator !== null ? Paginator.total_item : 0}
                                        onChangePage={(page) => setPage(page)}
                                        onChangeRowsPerPage={(e) => setPageSize(e)}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal isOpen={modal_list} toggle={() => { ShowCreateForm(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { ShowCreateForm(); }}>{validation.values.id ? "Edit" : "Add"} User </ModalHeader>
                <form  className="tablelist-form wa" onSubmit={validation.handleSubmit}>
                    <ModalBody>
                        <div className="mb-3">
                            <Label htmlFor="name" className="form-label">Name</Label>
                            <Input
                                name="name"
                                className="form-control"
                                placeholder="Enter name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                    validation.touched.name && validation.errors.name ? true : false
                                }
                            />
                            {validation.touched.name && validation.errors.name ? (
                                <div className="text-danger text-sm">{validation.errors.name}</div>
                            ) : null}
                        </div>

                        <div className="mb-3">
                            <Label htmlFor="email" className="form-label">Email</Label>
                            <Input
                                name="email"
                                className="form-control"
                                placeholder="Enter email"
                                type="email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                    validation.touched.email && validation.errors.email ? true : false
                                }
                            />
                            {validation.touched.email && validation.errors.email ? (
                                <div className="text-danger text-sm">{validation.errors.email}</div>
                            ) : null}
                        </div>

                        <div className="mb-3">
                            <Label htmlFor="phone" className="form-label">Phone</Label>
                            <Input
                                name="phone"
                                className="form-control"
                                placeholder="Enter phone (without +62/0, example: 8137012345)"
                                type="number"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.phone || ""}
                                invalid={
                                    validation.touched.phone && validation.errors.phone ? true : false
                                }
                            />
                            {validation.touched.phone && validation.errors.phone ? (
                                <div className="text-danger text-sm">{validation.errors.phone}</div>
                            ) : null}
                        </div>

                        <div className="mb-3">
                            <Label htmlFor="username" className="form-label">Username</Label>
                            <Input
                                name="username"
                                className="form-control"
                                placeholder="Enter username"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.username || ""}
                                invalid={
                                    validation.touched.username && validation.errors.username ? true : false
                                }
                            />
                            {validation.touched.username && validation.errors.username ? (
                                <div className="text-danger text-sm">{validation.errors.username}</div>
                            ) : null}
                        </div>

                        <div className="mb-3">
                            <Label className="form-label" htmlFor="password-input">Password {validation.values.id && "(Optional)"}</Label>
                            <div className="position-relative auth-pass-inputgroup mb-3">
                                <Input
                                    name="password"
                                    value={validation.values.password || ""}
                                    type={passwordShow ? "text" : "password"}
                                    className="form-control pe-5"
                                    placeholder="Enter Password"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                        validation.touched.password && validation.errors.password ? true : false
                                    }
                                />
                                {validation.touched.password && validation.errors.password ? (
                                    <div className="text-danger text-sm">{validation.errors.password}</div>
                                ) : null}
                                <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}><i className="ri-eye-fill align-middle"></i></button>
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="profile" className="form-label">Photo profile (optional)</label>
                            <input type="file" onChange={validation.handleChange}
                                   onBlur={validation.handleBlur} id="profile" name="profile" className="form-control" placeholder="Enter profile" />
                        </div>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                            <Label className="form-label" htmlFor="role">Role</Label>
                            <select className="form-select" aria-label="select example" id="role" name="role" onChange={validation.handleChange}
                                    onBlur={validation.handleBlur} value={validation.values.role || ""}>
                                <option value="">- Select role -</option>
                                <option value="ROLE_SYSTEM">Admin</option>
                                <option value="ROLE_USER">User</option>
                            </select>

                            {validation.touched.role && validation.errors.role ? (
                                <div className="text-danger text-sm">{validation.errors.role}</div>
                            ) : null}
                        </div>

                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Close</button>
                            <button type="submit" className="btn btn-success" id="add-btn">{validation.values.id ? "Edit" : "Add"} User</button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </form>
            </Modal>
        </React.Fragment>
    );
}

export default User




