import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import imageCompression from "browser-image-compression";
import { useFormik } from "formik";
import moment from "moment-timezone";
import { useGeolocated } from "react-geolocated";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import watermark from "watermarkjs/lib";
import * as Yup from "yup";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useProfile } from "../../../Components/Hooks/UserHooks";
import { axios } from "../../../helpers/axios";

const CreateSurvey = (props) => {
  document.title = "Create Survey | Surveyor app";
  const navigate = useNavigate();
  const [promoData, setPromoData] = useState([]);
  const [geolocation, setGeolocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [watermarkData, setWatermarkData] = useState(null);
  const [cameraCompressData, setcameraCompressData] = useState(null);
  const [preview, setPreview] = useState(false);
  const [cameraData, setCameraData] = useState(undefined);
  const [loadingAction, setLoadingAction] = useState(false);

  const [categories1, setCategories1] = useState([]);
  const [categories2, setCategories2] = useState([]);
  const [promos, setPromos] = useState([]);

  const [brands, setBrands] = useState([]);

  const [products, setProducts] = useState([]);
  const [measurements, setMeasurements] = useState([]);
  const [optionMeasurement, setOptionMeasurement] = useState([]);
  const [modalProduct, setModalProduct] = useState(false);
  const { userProfile } = useProfile();
  const [config, setConfig] = useState(undefined);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const [loadingMessage, setLoadingMessage] = useState("");
  // ---- location
  const {
    coords,
    getPosition,
    isGeolocationAvailable,
    isGeolocationEnabled,
    positionError,
  } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
      maximumAge: 0,
      timeout: Infinity,
    },
    onError: (positionError) => {
      console.log(positionError);
    },
    watchPosition: true,
    watchLocationPermissionChange: true,
    userDecisionTimeout: null, // --- 1 minute to decision
  });

  useEffect(() => {
    const fetchAllCategory1 = async () => {
      await axios
        .get(process.env.REACT_APP_API_URL + `/v1/categories1`)
        .then((resp) => {
          const data = resp.data;
          setCategories1(data);
        })
        .catch((err) => {
          Swal.fire({
            title: "Oops",
            text: "Something error with our server",
            icon: "error",
          });
        });
    };

    const fetchAllCategory2 = async () => {
      await axios
        .get(process.env.REACT_APP_API_URL + `/v1/categories2`)
        .then((resp) => {
          const data = resp.data;
          setCategories2(data);
        })
        .catch((err) => {
          Swal.fire({
            title: "Oops",
            text: "Something error with our server",
            icon: "error",
          });
        });
    };

    const fetchConfig = async () => {
      const resp = await axios
        .get(process.env.REACT_APP_API_URL + `/v1/config`)
        .catch((err) => {
          Swal.fire({
            title: "Oops",
            text: "Something error with our server",
            icon: "error",
          });
        });
      const data = resp.data;
      setConfig(data);

      let timeFormat = "YYYY-MM-DD HH:mm:ss";
      const start = data.time_start.split(":");
      let startCheck = moment()
        .tz("Asia/Jakarta")
        .hour(start[0])
        .minute(start[1])
        .second("00");

      const end = data.time_end.split(":");
      let endCheck = moment()
        .tz("Asia/Jakarta")
        .hour(end[0])
        .minute(end[1])
        .second("00");

      if (startCheck.isAfter(endCheck)) {
        endCheck = endCheck.add(1, "d");
      }

      startCheck = startCheck.format(timeFormat);
      endCheck = endCheck.format(timeFormat);

      const isFormOpen = moment(
        moment().tz("Asia/Jakarta").format(timeFormat)
      ).isBetween(startCheck, endCheck);
      console.log(startCheck);
      console.log(endCheck);
      console.log(
        moment(moment().tz("Asia/Jakarta").format(timeFormat)).format(
          timeFormat
        )
      );
      setIsFormOpen(isFormOpen);
    };

    const fetchAllPromo = async () => {
      await axios
        .get(process.env.REACT_APP_API_URL + `/v1/promo`)
        .then((resp) => {
          const data = resp.data;
          setPromos(data);
        })
        .catch((err) => {
          Swal.fire({
            title: "Oops",
            text: "Something error with our server",
            icon: "error",
          });
        });
    };

    const fetchAllbrands = async () => {
      await axios
        .get(process.env.REACT_APP_API_URL + `/v1/brands`)
        .then((resp) => {
          const data = resp.data;
          setBrands(data);
        })
        .catch((err) => {
          Swal.fire({
            title: "Oops",
            text: "Something error with our server",
            icon: "error",
          });
        });
    };

    const fetchAllMeasurements = async () => {
      await axios
        .get(process.env.REACT_APP_API_URL + `/v1/measurements`)
        .then((resp) => {
          const data = resp.data;
          setMeasurements(data);
        })
        .catch((err) => {
          Swal.fire({
            title: "Oops",
            text: "Something error with our server",
            icon: "error",
          });
        });
    };

    const fetchAllProduct = async () => {
      await axios
        .get(process.env.REACT_APP_API_URL + `/v1/products`)
        .then((resp) => {
          const data = resp.data;
          setProducts(data);
        })
        .catch((err) => {
          Swal.fire({
            title: "Oops",
            text: "Something error with our server",
            icon: "error",
          });
        });
    };

    fetchConfig();
    fetchAllCategory1();
    fetchAllCategory2();
    fetchAllPromo();
    fetchAllbrands();
    fetchAllMeasurements();
    fetchAllProduct();
  }, []);

  const divMap = useRef(null);

  useEffect(() => {
    if (cameraData !== null && cameraData !== undefined) {
      setLoading(true);
      setLoadingMessage("System sedang membuat geotag");
      convertWatermark();
    }
  }, [cameraData]);

  const fetchGeocodingReverse = async () => {
    let encode = encodeURIComponent(coords.latitude + "," + coords.longitude);
    const data = await axios.get(
      process.env.REACT_APP_API_URL + `/v1/location?q=${encode}`
    );
    setGeolocation(data.data);
    return data.data;
  };

  const convertWatermark = async () => {
    let cameraFile = cameraData;
    let mapFile = null;

    var _URL = window.URL || window.webkitURL;
    let img = new Image();
    var objectUrl = _URL.createObjectURL(cameraFile);

    console.log(objectUrl);

    img.onload = async function () {
      let width = parseInt(0.35 * this.width);

      if (this.width < this.height) {
        setLoadingMessage("Pastikan foto dengan mode landscape");
        return;
      }

      if (this.width >= 2337) {
        width = 800;
      }

      await fetch(
        "https://maps.googleapis.com/maps/api/staticmap?center=" +
          coords.latitude +
          "," +
          coords.longitude +
          "&zoom=20&size=" +
          width +
          "x" +
          width +
          "&markers=" +
          coords.latitude +
          "," +
          coords.longitude +
          "&key=" +
          process.env.REACT_APP_MAP_KEY
      )
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "mapFile", { type: "image/png" });
          mapFile = file;
        });
      setLoadingMessage(
        `1. System selesai membuat maps, dilanjutkan compress image`
      );
      const geocoding = await fetchGeocodingReverse();
      await convert(cameraFile, mapFile, geocoding);
      _URL.revokeObjectURL(objectUrl);
    };
    img.src = objectUrl;

    console.log("ini image", img);
  };

  const convert = async (camera, screenshotMap, geocoding) => {
    // const data = geocoding.results

    var getX = function (target, mark) {
      return target.width - (mark.width + 10);
    };

    var getY = function (target, mark) {
      return target.height - 50;
    };

    setLoadingMessage(
      `2. Proses compress image ... mohon ditunggu, file semula ${
        camera.size / 1024 / 1024
      } MB`
    );
    const compressedFile = await imageCompression(camera, {
      maxSizeMB: 0.5,
      onProgress: (n) => {
        setLoadingMessage(`Mohon ditunggu,  progress compress ${n}%`);
      },
      useWebWorker: true,
    });

    const provinsi = geocoding.province || "";
    const kabupaten = geocoding.regency || "";
    const kecamatan = geocoding.district || "";
    const kelurahan = geocoding.sub_district || "";
    const kodepos = geocoding.postal_code || "";
    const road = geocoding.formatted_address;

    console.log(screenshotMap, "----->screenshot");
    console.log(compressedFile, "-----> photo");
    console.log(camera, "-----> without compress");
    setLoadingMessage(
      `3. compress image selesai, dilanjutkan pembuatan geotag, file compress ${
        compressedFile.size / 1024 / 1024
      } MB`
    );

    const wrapTextUpperRight = (
      target,
      text,
      x,
      y,
      maxWidth,
      lineHeight,
      alpha
    ) => {
      var context = target.getContext("2d");
      context.font = "bold " + target.width * 0.025 + "px Arial";
      context.textAlign = "right";
      context.fillStyle = "white";
      context.globalAlpha = alpha;
      context.shadowColor = "black";
      context.shadowOffsetY = 1;
      context.shadowOffsetX = 1;
      context.shadowOpacity = 1;

      const words = text.split(" ");
      let line = "";

      let lineCounter = "";
      let counter = 0;
      let ycounter = y;

      for (let n = 0; n < words.length; n++) {
        const testLine = lineCounter + words[n] + " ";
        const metrics = context.measureText(testLine);
        const testWidth = metrics.width;
        if (testWidth > maxWidth && n > 0) {
          lineCounter = words[n] + " ";
          ycounter += lineHeight;
          counter += 1;
        } else {
          lineCounter = testLine;
        }
      }

      let fromBottom = target.height * 0.001;

      y = lineHeight * counter - fromBottom;
      if (y < 0) {
        y = 100;
      }

      for (let n = 0; n < words.length; n++) {
        const testLine = line + words[n] + " ";
        const metrics = context.measureText(testLine);
        const testWidth = metrics.width;
        if (testWidth > maxWidth && n > 0) {
          context.fillText(line, x, y);
          line = words[n] + " ";
          y += lineHeight;
        } else {
          line = testLine;
        }
      }

      context.fillText(line, x, y);
      return target;
    };

    const wrapText = (target, text, x, y, maxWidth, lineHeight, alpha) => {
      console.log(target);
      var context = target.getContext("2d");
      context.font = "bold " + target.width * 0.025 + "px Arial";
      context.textAlign = "right";
      context.fillStyle = "white";
      context.globalAlpha = alpha;
      context.shadowColor = "black";
      context.shadowOffsetY = 1;
      context.shadowOffsetX = 1;
      context.shadowOpacity = 1;

      const words = text.split(" ");
      let line = "";

      let lineCounter = "";
      let counter = 0;
      let ycounter = y;

      for (let n = 0; n < words.length; n++) {
        const testLine = lineCounter + words[n] + " ";
        const metrics = context.measureText(testLine);
        const testWidth = metrics.width;
        if (testWidth > maxWidth && n > 0) {
          lineCounter = words[n] + " ";
          ycounter += lineHeight;
          counter += 1;
        } else {
          lineCounter = testLine;
        }
      }

      let fromBottom = target.height * 0.016;
      y = y - lineHeight * counter - fromBottom;

      for (let n = 0; n < words.length; n++) {
        const testLine = line + words[n] + " ";
        const metrics = context.measureText(testLine);
        const testWidth = metrics.width;
        if (testWidth > maxWidth && n > 0) {
          context.fillText(line, x, y);
          line = words[n] + " ";
          y += lineHeight;
        } else {
          line = testLine;
        }
      }
      context.fillText(line, x, target.height - fromBottom);
      return target;
    };

    // ----- Check unit promo
    const dataUnitPromo = promos.find(
      (r) => r.id === validation.values.promo_id.value
    );

    const date = moment().tz("Asia/Jakarta").format("DD-MM-YYYY HH:mm:ss");
    watermark([compressedFile, screenshotMap])
      .dataUrl((target) =>
        wrapTextUpperRight(
          target,
          dataUnitPromo.name,
          target.width - 10,
          target.height,
          0.35 * target.width,
          0.035 * target.height,
          1
        )
      )
      .render()
      .dataUrl((target) =>
        wrapText(
          target,
          `   ${date} ${coords.latitude}, ${coords.longitude}, ${road}`,
          target.width - 10,
          target.height,
          0.35 * target.width,
          0.035 * target.height,
          1
        )
      )
      .then((src) => {
        setWatermarkData(src);
        console.log("ini watermark", src);
        console.log("ini compress", compressedFile);

        setcameraCompressData(compressedFile);
        setLoading(false);
      });
  };

  const retakePhoto = () => {
    setGeolocation(null);
    setWatermarkData(null);
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      id: promoData.id || "",
      category_1_id: promoData.category_1_id || "",
      category_2_id: promoData.category_2_id || "",
      promo_id: promoData.promo_id || "",
      brand_id: promoData.brand_id || "",
      product_id: promoData.product_id || "",
      company_id: promoData.company_id || "",
      measurement_id: promoData.measurement_id || "",
    },
    validationSchema: Yup.object({
      id: Yup.string().nullable(),
      category_1_id: Yup.object().required("Please Select Category 1"),
      category_2_id: Yup.object().required("Please Select Category 2"),
      promo_id: Yup.object().required("Please Select Promo"),
      brand_id: Yup.object().required("Please Select Brand"),
      product_id: Yup.object().required("Please Select Product"),
      measurement_id: Yup.object().required("Please Select Measurement"),
    }),
    onSubmit: async (values) => {
      const geolocationData = geolocation;
      setLoadingAction(true);

      if (geolocationData.length === 0) {
        alert("Alamat tidak ditemukan");
        return;
      }

      if (values.id !== "" || values.id !== undefined) {
        const provinsi = geolocationData.province || "";
        const kabupaten = geolocationData.regency || "";
        const kecamatan = geolocationData.district || "";
        const kelurahan = geolocationData.sub_district || "";
        const kodepos = geolocationData.postal_code || "";
        const road = geolocationData.formatted_address;

        let formData = new FormData();
        let formDataCompress = new FormData();
        formDataCompress.append("file", cameraCompressData);
        await fetch(watermarkData)
          .then((res) => res.blob())
          .then(async (blob) => {
            setLoading(true);
            const file = new File([blob], "watermarkData", {
              type: "image/png",
            });
            const compressedFile = await imageCompression(file, {
              maxSizeMB: 10,
              onProgress: (n) => {
                setLoadingMessage(
                  `Mohon ditunggu, menyiapkan dokumen progress ${n}%`
                );
              },
            });
            console.log(
              `watermark after compress size ${
                compressedFile.size / 1024 / 1024
              } MB`
            );
            formData.append("file", compressedFile);
          });

        setLoadingMessage(`Memulai penyimpanan file`);
        const file = await axios.post(
          process.env.REACT_APP_API_URL + `/v1/s3/upload?folder=survey`,
          formData,
          {
            "Content-Type": "multipart/form-data",
          }
        );

        const file_compress = await axios.post(
          process.env.REACT_APP_API_URL + `/v1/s3/upload?folder=survey_raw`,
          formDataCompress,
          {
            "Content-Type": "multipart/form-data",
          }
        );

        const payload = {
          image: file.data.path,
          image_camera_compress: file_compress.data.path,
          lat: coords.latitude.toString(),
          long: coords.longitude.toString(),

          province: provinsi,
          regency: kabupaten,
          district: kecamatan,
          state: kelurahan,
          postal_code: kodepos,

          // generated_address:geolocationData[0].formatted,
          generated_address: road,

          category_1_id: values.category_1_id.value,
          category_2_id: values.category_2_id.value,
          promo_id: values.promo_id.value,

          brand_id: values.brand_id.value,
          product_id: values.product_id.value,
          measurement_id: values.measurement_id.value,
        };

        setLoadingMessage(`Memulai penyimpanan data`);
        const { data } = await axios.post(
          process.env.REACT_APP_API_URL + `/v1/survey`,
          payload
        );
        if (data === "ok") {
          setLoadingMessage(``);
          setLoading(false);
          setLoadingAction(false);
          navigate("/survey");
        } else {
          alert("terjadi kesalahan saat insert");
          return;
        }
      } else {
        // update
      }
    },
  });

  useEffect(() => {
    validation.setFieldValue("category_2_id", "");
  }, [validation.values.category_1_id]);

  useEffect(() => {
    validation.setFieldValue("product_id", "");
  }, [validation.values.brand_id]);

  useEffect(() => {
    if (
      validation.values.promo_id !== null &&
      validation.values.promo_id !== ""
    ) {
      const promo = promos.find(
        (row) => row.id === validation.values.promo_id.value
      );
      const measurementOption = measurements
        .filter((row) => promo.measurements.includes(row.id))
        .map((row) => {
          return {
            value: row.id,
            label: row.name,
          };
        });
      validation.setFieldValue("measurement_id", "");
      setOptionMeasurement(measurementOption);
    }
  }, [validation.values.promo_id]);

  const showModalProduct = () => {
    setModalProduct(!modalProduct);
    validationProduct.setFieldValue("name", "");
    validationProduct.setFieldValue("brand_id", "");
  };

  const validationProduct = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: "",
      brand_id: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter name"),
      brand_id: Yup.string().required("Please Select brand"),
    }),
    onSubmit: async (values) => {
      const payload = {
        name: values.name,
        brand_id: parseInt(values.brand_id),
      };
      const { data } = await axios.post(
        process.env.REACT_APP_API_URL + `/v1/products`,
        payload
      );
      if (data === "ok") {
        Swal.fire({
          title: "Success",
          text: "Success create product",
          icon: "success",
        })
          .then((resp) => {
            const fetchAllProduct = async () => {
              await axios
                .get(process.env.REACT_APP_API_URL + `/v1/products`)
                .then((resp) => {
                  const data = resp.data;
                  setProducts(data);
                })
                .catch((err) => {
                  Swal.fire({
                    title: "Oops",
                    text: "Something error with our server",
                    icon: "error",
                  });
                });
            };
            fetchAllProduct();
          })
          .then((e) => {
            showModalProduct();
          });
      }
    },
  });
  // useEffect(() => {
  //     validation.setFieldValue("measurement_id", "")
  // }, [validation.values.product_id]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Create Survey" pageTitle="Survey" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="align-items-center">
                    <Col>
                      <h4 className="card-title mb-0 flex-grow-1">
                        Create survey
                      </h4>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => navigate("/survey")}
                        color="success"
                        className="add-btn"
                        id="create-btn"
                      >
                        Back
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  {userProfile.role === "ROLE_SYSTEM" ? (
                    <>
                      {!isGeolocationAvailable ? (
                        <div>Your browser does not support Geolocation</div>
                      ) : !isGeolocationEnabled ? (
                        <div>
                          Wait a while system checking status geolocation,
                          measure accept location service ..
                        </div>
                      ) : coords ? (
                        <>
                          {coords.latitude && coords.longitude && (
                            <>
                              <Row className="align-items-center m-0">
                                <Col
                                  className="col-sm-auto overflow-auto"
                                  lg={4}
                                >
                                  <iframe
                                    width="300"
                                    height="300"
                                    style={{ border: "0" }}
                                    loading="lazy"
                                    allowFullScreen={true}
                                    referrerPolicy="no-referrer-when-downgrade"
                                    src={
                                      "https://www.google.com/maps/embed/v1/place?key=" +
                                      process.env.REACT_APP_MAP_KEY +
                                      "&q=" +
                                      coords.latitude +
                                      "," +
                                      coords.longitude
                                    }
                                  ></iframe>
                                </Col>
                                <Col
                                  lg={8}
                                  className="col-sm-auto mt-2 overflow-auto"
                                >
                                  {geolocation !== null && (
                                    <>
                                      <table className="mb-3 overflow-auto">
                                        <thead>
                                          <tr>
                                            <td>Provinsi</td>
                                            <td>:</td>
                                            <td>{geolocation.province}</td>
                                          </tr>
                                          <tr>
                                            <td>Kabupaten</td>
                                            <td>:</td>
                                            <td>{geolocation.regency}</td>
                                          </tr>
                                          <tr>
                                            <td>Kecamatan</td>
                                            <td>:</td>
                                            <td>{geolocation.district}</td>
                                          </tr>
                                          <tr>
                                            <td>Desa/kelurahan</td>
                                            <td>:</td>
                                            <td>{geolocation.sub_district}</td>
                                          </tr>
                                          <tr>
                                            <td>Kodepos</td>
                                            <td>:</td>
                                            <td>{geolocation.postal_code}</td>
                                          </tr>
                                          <tr>
                                            <td>Detail alamat</td>
                                            <td>:</td>
                                            <td>
                                              {geolocation.formatted_address}{" "}
                                              (lat,long: {coords.latitude},
                                              {coords.longitude})
                                            </td>
                                          </tr>
                                        </thead>
                                      </table>
                                    </>
                                  )}
                                </Col>
                              </Row>
                              <Row className="mt-2 ma-1">
                                <Col>
                                  <button
                                    onClick={getPosition}
                                    className={"btn btn-sm btn-primary"}
                                  >
                                    Sync location
                                  </button>
                                </Col>
                              </Row>
                            </>
                          )}
                          <div width={"100%"} className="my-3 overflow-auto">
                            {watermarkData !== null && preview && (
                              <img src={watermarkData} alt={"watermark"} />
                            )}
                          </div>
                          {loading && !loadingAction && (
                            <div className="alert alert-primary" role="alert">
                              {loadingMessage}
                            </div>
                          )}
                          {watermarkData == null && (
                            <Col sm={12} lg={12}>
                              <div className="position-relative mb-3">
                                <Label
                                  className="form-label"
                                  htmlFor="category_2_id"
                                >
                                  Unit Promo
                                </Label>
                                <Select
                                  name="promo"
                                  options={promos.map((row) => {
                                    return {
                                      value: row.id,
                                      label: `${row.code} - ${row.name}`,
                                    };
                                  })}
                                  defaultValue={promos.find((row) => {
                                    if (row.id === validation.values.brand_id) {
                                      return {
                                        value: row.id,
                                        label: row.name,
                                      };
                                    }
                                    return "";
                                  })}
                                  value={validation.values.promo_id ?? ""}
                                  onChange={(e) =>
                                    validation.setFieldValue("promo_id", e)
                                  }
                                  onBlur={validation.handleBlur}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                />

                                {validation.touched.promo_id &&
                                validation.errors.promo_id ? (
                                  <div className="text-danger text-sm">
                                    {validation.errors.promo_id}
                                  </div>
                                ) : null}
                              </div>
                            </Col>
                          )}

                          {validation.values.promo_id !== "" && (
                            <div>
                              <div className="alert alert-danger" role="alert">
                                Make sure use portrait mode & take it within 20
                                meters of the object
                              </div>
                              <label htmlFor="profile" className="form-label">
                                Photo survey
                              </label>
                              <input
                                type="file"
                                capture="environment"
                                accept={"image/*"}
                                onChange={(e) =>
                                  setCameraData(e.target.files[0])
                                }
                                id="photo"
                                name="photo"
                                className="form-control"
                                placeholder="Enter profile"
                              />
                            </div>
                          )}

                          {watermarkData !== null &&
                            validation.values.promo_id !== "" && (
                              <>
                                <div className="d-flex justify-content-center">
                                  <button
                                    className="btn btn-warning mt-2 me-2 mb-3"
                                    type="button"
                                    onClick={() => setPreview(!preview)}
                                  >
                                    {preview ? "Tutup preview" : "Preview"}
                                  </button>
                                  <button
                                    disabled={loadingAction}
                                    className="btn btn-info mt-2 mb-3"
                                    type="button"
                                    onClick={retakePhoto}
                                  >
                                    Reupload photo
                                  </button>
                                </div>

                                <form onSubmit={validation.handleSubmit}>
                                  <Row>
                                    <Col sm={12} lg={6}>
                                      <div className="position-relative mb-3">
                                        <Label
                                          className="form-label"
                                          htmlFor="category_1_id"
                                        >
                                          Lighting
                                        </Label>
                                        <Select
                                          name="category_1_id"
                                          defaultValue={categories1.find(
                                            (row) => {
                                              if (
                                                row.id ===
                                                validation.values.category_1_id
                                              ) {
                                                return {
                                                  value: row.id,
                                                  label: row.name,
                                                };
                                              }
                                              return "";
                                            }
                                          )}
                                          options={categories1.map((row) => {
                                            return {
                                              value: row.id,
                                              label: row.name,
                                            };
                                          })}
                                          onChange={(e) =>
                                            validation.setFieldValue(
                                              "category_1_id",
                                              e
                                            )
                                          }
                                          onBlur={validation.handleBlur}
                                          className="basic-multi-select"
                                          classNamePrefix="select"
                                        />

                                        {validation.touched.category_1_id &&
                                        validation.errors.category_1_id ? (
                                          <div className="text-danger text-sm">
                                            {validation.errors.category_1_id}
                                          </div>
                                        ) : null}
                                      </div>
                                    </Col>
                                    <Col sm={12} lg={6}>
                                      <div className="position-relative mb-3">
                                        <Label
                                          className="form-label"
                                          htmlFor="category_2_id"
                                        >
                                          Unit 3D
                                        </Label>
                                        <Select
                                          name="colors"
                                          options={categories2
                                            .filter(
                                              (row) =>
                                                row.category_1_id ===
                                                validation.values.category_1_id
                                                  .value
                                            )
                                            .map((row) => {
                                              return {
                                                value: row.id,
                                                label: row.name,
                                              };
                                            })}
                                          defaultValue={categories2
                                            .filter(
                                              (row) =>
                                                row.category_1_id ===
                                                validation.values.category_1_id
                                                  .value
                                            )
                                            .find((row) => {
                                              if (
                                                row.id ===
                                                validation.values.category_2_id
                                              ) {
                                                return {
                                                  value: row.id,
                                                  label: row.name,
                                                };
                                              }
                                              return "";
                                            })}
                                          value={
                                            validation.values.category_2_id ??
                                            ""
                                          }
                                          onChange={(e) =>
                                            validation.setFieldValue(
                                              "category_2_id",
                                              e
                                            )
                                          }
                                          onBlur={validation.handleBlur}
                                          className="basic-multi-select"
                                          classNamePrefix="select"
                                        />

                                        {validation.touched.category_2_id &&
                                        validation.errors.category_2_id ? (
                                          <div className="text-danger text-sm">
                                            {validation.errors.category_2_id}
                                          </div>
                                        ) : null}
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm={12}>
                                      <div className="position-relative mb-3">
                                        <Label
                                          className="form-label"
                                          htmlFor="category_2_id"
                                        >
                                          Measurement
                                        </Label>
                                        <Select
                                          name="measurement"
                                          options={optionMeasurement}
                                          defaultValue={measurements.find(
                                            (row) => {
                                              if (
                                                row.id ===
                                                validation.values.measurement_id
                                              ) {
                                                return {
                                                  value: row.id,
                                                  label: row.name,
                                                };
                                              }
                                              return "";
                                            }
                                          )}
                                          value={
                                            validation.values.measurement_id ??
                                            ""
                                          }
                                          onChange={(e) =>
                                            validation.setFieldValue(
                                              "measurement_id",
                                              e
                                            )
                                          }
                                          onBlur={validation.handleBlur}
                                          className="basic-multi-select"
                                          classNamePrefix="select"
                                        />

                                        {validation.touched.measurement_id &&
                                        validation.errors.measurement_id ? (
                                          <div className="text-danger text-sm">
                                            {validation.errors.measurement_id}
                                          </div>
                                        ) : null}
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm={12} lg={6}>
                                      <div className="position-relative mb-3">
                                        <Label
                                          className="form-label"
                                          htmlFor="category_2_id"
                                        >
                                          Brand
                                        </Label>
                                        <Select
                                          name="brand"
                                          options={brands.map((row) => {
                                            return {
                                              value: row.id,
                                              label: `${row.name}`,
                                            };
                                          })}
                                          defaultValue={brands.find((row) => {
                                            if (
                                              row.id ===
                                              validation.values.brand_id
                                            ) {
                                              return {
                                                value: row.id,
                                                label: row.name,
                                              };
                                            }
                                            return "";
                                          })}
                                          value={
                                            validation.values.brand_id ?? ""
                                          }
                                          onChange={(e) =>
                                            validation.setFieldValue(
                                              "brand_id",
                                              e
                                            )
                                          }
                                          onBlur={validation.handleBlur}
                                          className="basic-multi-select mt-1"
                                          classNamePrefix="select"
                                        />

                                        {validation.touched.brand_id &&
                                        validation.errors.brand_id ? (
                                          <div className="text-danger text-sm">
                                            {validation.errors.brand_id}
                                          </div>
                                        ) : null}
                                      </div>
                                    </Col>
                                    <Col sm={12} lg={6}>
                                      <div className="position-relative mb-3">
                                        <div className="d-flex align-items-center justify-content-between">
                                          <Label
                                            className="form-label"
                                            htmlFor="category_2_id"
                                          >
                                            Product
                                          </Label>
                                          <div>
                                            Product tidak ditemukan?{" "}
                                            <button
                                              disabled={loadingAction}
                                              type={"button"}
                                              onClick={showModalProduct}
                                              className="btn btn-sm btn-outline-primary mb-1"
                                            >
                                              Tambah
                                            </button>
                                          </div>
                                        </div>
                                        <Select
                                          name="product_id"
                                          options={products
                                            .filter(
                                              (row) =>
                                                row.brand_id ===
                                                validation.values.brand_id.value
                                            )
                                            .map((row) => {
                                              return {
                                                value: row.id,
                                                label: `${row.name}`,
                                              };
                                            })}
                                          defaultValue={products
                                            .filter(
                                              (row) =>
                                                row.brand_id ===
                                                validation.values.brand_id.value
                                            )
                                            .find((row) => {
                                              if (
                                                row.id ===
                                                validation.values.product_id
                                              ) {
                                                return {
                                                  value: row.id,
                                                  label: row.name,
                                                };
                                              }
                                              return "";
                                            })}
                                          value={
                                            validation.values.product_id ?? ""
                                          }
                                          onChange={(e) =>
                                            validation.setFieldValue(
                                              "product_id",
                                              e
                                            )
                                          }
                                          onBlur={validation.handleBlur}
                                          className="basic-multi-select"
                                          classNamePrefix="select"
                                        />

                                        {validation.touched.product_id &&
                                        validation.errors.product_id ? (
                                          <div className="text-danger text-sm">
                                            {validation.errors.product_id}
                                          </div>
                                        ) : null}
                                      </div>
                                    </Col>
                                  </Row>

                                  {loadingAction && (
                                    <div
                                      className="alert alert-primary"
                                      role="alert"
                                    >
                                      Loading submit data, {loadingMessage}
                                    </div>
                                  )}
                                  <button
                                    disabled={loadingAction}
                                    className="btn btn-success mt-2 mb-3"
                                    type="submit"
                                  >
                                    Submit
                                  </button>
                                </form>

                                <Modal
                                  isOpen={modalProduct}
                                  toggle={() => {
                                    showModalProduct();
                                  }}
                                  centered
                                >
                                  <ModalHeader
                                    className="bg-light p-3"
                                    id="exampleModalLabel"
                                    toggle={() => {
                                      showModalProduct();
                                    }}
                                  >
                                    {" "}
                                    Add product{" "}
                                  </ModalHeader>
                                  <form
                                    className="tablelist-form wa"
                                    onSubmit={validationProduct.handleSubmit}
                                  >
                                    <ModalBody>
                                      <div className="mb-3">
                                        <Label
                                          htmlFor="name"
                                          className="form-label"
                                        >
                                          Name
                                        </Label>
                                        <Input
                                          name="name"
                                          className="form-control"
                                          placeholder="Enter name"
                                          type="text"
                                          onChange={
                                            validationProduct.handleChange
                                          }
                                          onBlur={validationProduct.handleBlur}
                                          value={
                                            validationProduct.values.name || ""
                                          }
                                          invalid={
                                            validationProduct.touched.name &&
                                            validationProduct.errors.name
                                              ? true
                                              : false
                                          }
                                        />
                                        {validationProduct.touched.name &&
                                        validationProduct.errors.name ? (
                                          <div className="text-danger text-sm">
                                            {validationProduct.errors.name}
                                          </div>
                                        ) : null}
                                      </div>

                                      <div className="position-relative auth-pass-inputgroup mb-3">
                                        <Label
                                          className="form-label"
                                          htmlFor="brand_id"
                                        >
                                          Brand
                                        </Label>
                                        <select
                                          className="form-select"
                                          aria-label="select example"
                                          id="brand_id"
                                          name="brand_id"
                                          onChange={
                                            validationProduct.handleChange
                                          }
                                          onBlur={validationProduct.handleBlur}
                                          value={
                                            validationProduct.values.brand_id ||
                                            ""
                                          }
                                        >
                                          <option value="">
                                            - Select brand -
                                          </option>
                                          {brands.map((row) => {
                                            return (
                                              <option
                                                key={row.id}
                                                value={row.id}
                                              >
                                                {row.name}
                                              </option>
                                            );
                                          })}
                                        </select>

                                        {validationProduct.touched.brand_id &&
                                        validationProduct.errors.brand_id ? (
                                          <div className="text-danger text-sm">
                                            {validationProduct.errors.brand_id}
                                          </div>
                                        ) : null}
                                      </div>
                                    </ModalBody>
                                    <ModalFooter>
                                      <div className="hstack gap-2 justify-content-end">
                                        <button
                                          type="button"
                                          className="btn btn-light"
                                          onClick={() => setModalProduct(false)}
                                        >
                                          Close
                                        </button>
                                        <button
                                          type="submit"
                                          disabled={loadingAction}
                                          className="btn btn-success"
                                          id="add-btn"
                                        >
                                          Tambah product
                                        </button>
                                      </div>
                                    </ModalFooter>
                                  </form>
                                </Modal>
                              </>
                            )}
                        </>
                      ) : (
                        <div>Getting the location data&hellip; </div>
                      )}
                    </>
                  ) : userProfile.role === "ROLE_USER" && isFormOpen ? (
                    <>
                      {!isGeolocationAvailable ? (
                        <div>Your browser does not support Geolocation</div>
                      ) : !isGeolocationEnabled ? (
                        <div>
                          Wait a while system checking status geolocation,
                          measure accept location service ..
                        </div>
                      ) : coords ? (
                        <>
                          {coords.latitude && coords.longitude && (
                            <>
                              <div className="alert alert-warning" role="alert">
                                Input survey akan ditutup setelah jam{" "}
                                {config?.time_end} WIB
                              </div>
                              <Row className="align-items-center m-0">
                                <Col
                                  className="col-sm-auto overflow-auto"
                                  lg={4}
                                >
                                  <iframe
                                    width="300"
                                    height="300"
                                    style={{ border: "0" }}
                                    loading="lazy"
                                    allowFullScreen={true}
                                    referrerPolicy="no-referrer-when-downgrade"
                                    src={
                                      "https://www.google.com/maps/embed/v1/place?key=" +
                                      process.env.REACT_APP_MAP_KEY +
                                      "&q=" +
                                      coords.latitude +
                                      "," +
                                      coords.longitude
                                    }
                                  ></iframe>
                                </Col>
                                <Col
                                  lg={8}
                                  className="col-sm-auto mt-2 overflow-auto"
                                >
                                  {geolocation !== null && (
                                    <>
                                      <table className="mb-3 overflow-auto">
                                        <thead>
                                          <tr>
                                            <td>Provinsi</td>
                                            <td>:</td>
                                            <td>{geolocation.province}</td>
                                          </tr>
                                          <tr>
                                            <td>Kabupaten</td>
                                            <td>:</td>
                                            <td>{geolocation.regency}</td>
                                          </tr>
                                          <tr>
                                            <td>Kecamatan</td>
                                            <td>:</td>
                                            <td>{geolocation.district}</td>
                                          </tr>
                                          <tr>
                                            <td>Desa/kelurahan</td>
                                            <td>:</td>
                                            <td>{geolocation.sub_district}</td>
                                          </tr>
                                          <tr>
                                            <td>Kodepos</td>
                                            <td>:</td>
                                            <td>{geolocation.postal_code}</td>
                                          </tr>
                                          <tr>
                                            <td>Detail alamat</td>
                                            <td>:</td>
                                            <td>
                                              {geolocation.formatted_address}{" "}
                                              (lat,long: {coords.latitude},
                                              {coords.longitude})
                                            </td>
                                          </tr>
                                        </thead>
                                      </table>
                                    </>
                                  )}
                                </Col>
                              </Row>
                              <Row className="mt-2 ma-1">
                                <Col>
                                  <button
                                    onClick={getPosition}
                                    className={"btn btn-sm btn-primary"}
                                  >
                                    Sync location
                                  </button>
                                </Col>
                              </Row>
                            </>
                          )}

                          <div width={"100%"} className="my-3 overflow-auto">
                            {watermarkData !== null && preview && (
                              <img src={watermarkData} alt={"watermark"} />
                            )}
                          </div>

                          {loading && !loadingAction && (
                            <div className="alert alert-primary" role="alert">
                              {loadingMessage}
                            </div>
                          )}

                          {watermarkData == null && (
                            <Col sm={12} lg={12}>
                              <div className="position-relative mb-3">
                                <Label
                                  className="form-label"
                                  htmlFor="category_2_id"
                                >
                                  Unit Promo
                                </Label>
                                <Select
                                  name="promo"
                                  options={promos.map((row) => {
                                    return {
                                      value: row.id,
                                      label: `${row.code} - ${row.name}`,
                                    };
                                  })}
                                  defaultValue={promos.find((row) => {
                                    if (row.id === validation.values.brand_id) {
                                      return {
                                        value: row.id,
                                        label: row.name,
                                      };
                                    }
                                    return "";
                                  })}
                                  value={validation.values.promo_id ?? ""}
                                  onChange={(e) =>
                                    validation.setFieldValue("promo_id", e)
                                  }
                                  onBlur={validation.handleBlur}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                />

                                {validation.touched.promo_id &&
                                validation.errors.promo_id ? (
                                  <div className="text-danger text-sm">
                                    {validation.errors.promo_id}
                                  </div>
                                ) : null}
                              </div>
                            </Col>
                          )}

                          {validation.values.promo_id !== "" && (
                            <div>
                              <div className="alert alert-danger" role="alert">
                                Make sure use portrait mode & take it within 20
                                meters of the object
                              </div>
                              <label htmlFor="profile" className="form-label">
                                Photo survey
                              </label>
                              <input
                                type="file"
                                capture="environment"
                                accept={"image/*"}
                                onChange={(e) =>
                                  setCameraData(e.target.files[0])
                                }
                                id="photo"
                                name="photo"
                                className="form-control"
                                placeholder="Enter profile"
                              />
                            </div>
                          )}

                          {watermarkData !== null &&
                            validation.values.promo_id !== "" && (
                              <>
                                <div className="d-flex justify-content-center">
                                  <button
                                    className="btn btn-warning mt-2 me-2 mb-3"
                                    type="button"
                                    onClick={() => setPreview(!preview)}
                                  >
                                    {preview ? "Tutup preview" : "Preview"}
                                  </button>
                                  <button
                                    disabled={loadingAction}
                                    className="btn btn-info mt-2 mb-3"
                                    type="button"
                                    onClick={retakePhoto}
                                  >
                                    Reupload photo
                                  </button>
                                </div>

                                <form onSubmit={validation.handleSubmit}>
                                  <Row>
                                    <Col sm={12} lg={4}>
                                      <div className="position-relative mb-3">
                                        <Label
                                          className="form-label"
                                          htmlFor="category_1_id"
                                        >
                                          Lighting
                                        </Label>
                                        <Select
                                          name="category_1_id"
                                          defaultValue={categories1.find(
                                            (row) => {
                                              if (
                                                row.id ===
                                                validation.values.category_1_id
                                              ) {
                                                return {
                                                  value: row.id,
                                                  label: row.name,
                                                };
                                              }
                                              return "";
                                            }
                                          )}
                                          options={categories1.map((row) => {
                                            return {
                                              value: row.id,
                                              label: row.name,
                                            };
                                          })}
                                          onChange={(e) =>
                                            validation.setFieldValue(
                                              "category_1_id",
                                              e
                                            )
                                          }
                                          onBlur={validation.handleBlur}
                                          className="basic-multi-select"
                                          classNamePrefix="select"
                                        />

                                        {validation.touched.category_1_id &&
                                        validation.errors.category_1_id ? (
                                          <div className="text-danger text-sm">
                                            {validation.errors.category_1_id}
                                          </div>
                                        ) : null}
                                      </div>
                                    </Col>
                                    <Col sm={12} lg={4}>
                                      <div className="position-relative mb-3">
                                        <Label
                                          className="form-label"
                                          htmlFor="category_2_id"
                                        >
                                          Unit 3D
                                        </Label>
                                        <Select
                                          name="colors"
                                          options={categories2
                                            .filter(
                                              (row) =>
                                                row.category_1_id ===
                                                validation.values.category_1_id
                                                  .value
                                            )
                                            .map((row) => {
                                              return {
                                                value: row.id,
                                                label: row.name,
                                              };
                                            })}
                                          defaultValue={categories2
                                            .filter(
                                              (row) =>
                                                row.category_1_id ===
                                                validation.values.category_1_id
                                                  .value
                                            )
                                            .find((row) => {
                                              if (
                                                row.id ===
                                                validation.values.category_2_id
                                              ) {
                                                return {
                                                  value: row.id,
                                                  label: row.name,
                                                };
                                              }
                                              return "";
                                            })}
                                          value={
                                            validation.values.category_2_id ??
                                            ""
                                          }
                                          onChange={(e) =>
                                            validation.setFieldValue(
                                              "category_2_id",
                                              e
                                            )
                                          }
                                          onBlur={validation.handleBlur}
                                          className="basic-multi-select"
                                          classNamePrefix="select"
                                        />

                                        {validation.touched.category_2_id &&
                                        validation.errors.category_2_id ? (
                                          <div className="text-danger text-sm">
                                            {validation.errors.category_2_id}
                                          </div>
                                        ) : null}
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm={12}>
                                      <div className="position-relative mb-3">
                                        <Label
                                          className="form-label"
                                          htmlFor="category_2_id"
                                        >
                                          Measurement
                                        </Label>
                                        <Select
                                          name="measurement"
                                          options={optionMeasurement}
                                          defaultValue={measurements.find(
                                            (row) => {
                                              if (
                                                row.id ===
                                                validation.values.measurement_id
                                              ) {
                                                return {
                                                  value: row.id,
                                                  label: row.name,
                                                };
                                              }
                                              return "";
                                            }
                                          )}
                                          value={
                                            validation.values.measurement_id ??
                                            ""
                                          }
                                          onChange={(e) =>
                                            validation.setFieldValue(
                                              "measurement_id",
                                              e
                                            )
                                          }
                                          onBlur={validation.handleBlur}
                                          className="basic-multi-select"
                                          classNamePrefix="select"
                                        />

                                        {validation.touched.measurement_id &&
                                        validation.errors.measurement_id ? (
                                          <div className="text-danger text-sm">
                                            {validation.errors.measurement_id}
                                          </div>
                                        ) : null}
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm={12} lg={6}>
                                      <div className="position-relative mb-3">
                                        <Label
                                          className="form-label"
                                          htmlFor="category_2_id"
                                        >
                                          Brand
                                        </Label>
                                        <Select
                                          name="brand"
                                          options={brands.map((row) => {
                                            return {
                                              value: row.id,
                                              label: `${row.name}`,
                                            };
                                          })}
                                          defaultValue={brands.find((row) => {
                                            if (
                                              row.id ===
                                              validation.values.brand_id
                                            ) {
                                              return {
                                                value: row.id,
                                                label: row.name,
                                              };
                                            }
                                            return "";
                                          })}
                                          value={
                                            validation.values.brand_id ?? ""
                                          }
                                          onChange={(e) =>
                                            validation.setFieldValue(
                                              "brand_id",
                                              e
                                            )
                                          }
                                          onBlur={validation.handleBlur}
                                          className="basic-multi-select mt-1"
                                          classNamePrefix="select"
                                        />

                                        {validation.touched.brand_id &&
                                        validation.errors.brand_id ? (
                                          <div className="text-danger text-sm">
                                            {validation.errors.brand_id}
                                          </div>
                                        ) : null}
                                      </div>
                                    </Col>
                                    <Col sm={12} lg={6}>
                                      <div className="position-relative mb-3">
                                        <div className="d-flex align-items-center justify-content-between">
                                          <Label
                                            className="form-label"
                                            htmlFor="category_2_id"
                                          >
                                            Product
                                          </Label>
                                          <div>
                                            Product tidak ditemukan?{" "}
                                            <button
                                              type={"button"}
                                              onClick={showModalProduct}
                                              className="btn btn-sm btn-outline-primary mb-1"
                                            >
                                              Tambah
                                            </button>
                                          </div>
                                        </div>
                                        <Select
                                          name="product_id"
                                          options={products
                                            .filter(
                                              (row) =>
                                                row.brand_id ===
                                                validation.values.brand_id.value
                                            )
                                            .map((row) => {
                                              return {
                                                value: row.id,
                                                label: `${row.name}`,
                                              };
                                            })}
                                          defaultValue={products
                                            .filter(
                                              (row) =>
                                                row.brand_id ===
                                                validation.values.brand_id.value
                                            )
                                            .find((row) => {
                                              if (
                                                row.id ===
                                                validation.values.product_id
                                              ) {
                                                return {
                                                  value: row.id,
                                                  label: row.name,
                                                };
                                              }
                                              return "";
                                            })}
                                          value={
                                            validation.values.product_id ?? ""
                                          }
                                          onChange={(e) =>
                                            validation.setFieldValue(
                                              "product_id",
                                              e
                                            )
                                          }
                                          onBlur={validation.handleBlur}
                                          className="basic-multi-select"
                                          classNamePrefix="select"
                                        />

                                        {validation.touched.product_id &&
                                        validation.errors.product_id ? (
                                          <div className="text-danger text-sm">
                                            {validation.errors.product_id}
                                          </div>
                                        ) : null}
                                      </div>
                                    </Col>
                                  </Row>

                                  {loadingAction && (
                                    <div
                                      className="alert alert-primary"
                                      role="alert"
                                    >
                                      Loading submit data, {loadingMessage}
                                    </div>
                                  )}
                                  <button
                                    disabled={loadingAction}
                                    className="btn btn-success mt-2 mb-3"
                                    type="submit"
                                  >
                                    Submit
                                  </button>
                                </form>

                                <Modal
                                  isOpen={modalProduct}
                                  toggle={() => {
                                    showModalProduct();
                                  }}
                                  centered
                                >
                                  <ModalHeader
                                    className="bg-light p-3"
                                    id="exampleModalLabel"
                                    toggle={() => {
                                      showModalProduct();
                                    }}
                                  >
                                    {" "}
                                    Add product{" "}
                                  </ModalHeader>
                                  <form
                                    className="tablelist-form wa"
                                    onSubmit={validationProduct.handleSubmit}
                                  >
                                    <ModalBody>
                                      <div className="mb-3">
                                        <Label
                                          htmlFor="name"
                                          className="form-label"
                                        >
                                          Name
                                        </Label>
                                        <Input
                                          name="name"
                                          className="form-control"
                                          placeholder="Enter name"
                                          type="text"
                                          onChange={
                                            validationProduct.handleChange
                                          }
                                          onBlur={validationProduct.handleBlur}
                                          value={
                                            validationProduct.values.name || ""
                                          }
                                          invalid={
                                            validationProduct.touched.name &&
                                            validationProduct.errors.name
                                              ? true
                                              : false
                                          }
                                        />
                                        {validationProduct.touched.name &&
                                        validationProduct.errors.name ? (
                                          <div className="text-danger text-sm">
                                            {validationProduct.errors.name}
                                          </div>
                                        ) : null}
                                      </div>

                                      <div className="position-relative auth-pass-inputgroup mb-3">
                                        <Label
                                          className="form-label"
                                          htmlFor="brand_id"
                                        >
                                          Brand
                                        </Label>
                                        <select
                                          className="form-select"
                                          aria-label="select example"
                                          id="brand_id"
                                          name="brand_id"
                                          onChange={
                                            validationProduct.handleChange
                                          }
                                          onBlur={validationProduct.handleBlur}
                                          value={
                                            validationProduct.values.brand_id ||
                                            ""
                                          }
                                        >
                                          <option value="">
                                            - Select brand -
                                          </option>
                                          {brands.map((row) => {
                                            return (
                                              <option
                                                key={row.id}
                                                value={row.id}
                                              >
                                                {row.name}
                                              </option>
                                            );
                                          })}
                                        </select>

                                        {validationProduct.touched.brand_id &&
                                        validationProduct.errors.brand_id ? (
                                          <div className="text-danger text-sm">
                                            {validationProduct.errors.brand_id}
                                          </div>
                                        ) : null}
                                      </div>
                                    </ModalBody>
                                    <ModalFooter>
                                      <div className="hstack gap-2 justify-content-end">
                                        <button
                                          type="button"
                                          className="btn btn-light"
                                          onClick={() => setModalProduct(false)}
                                        >
                                          Close
                                        </button>
                                        <button
                                          disabled={loadingAction}
                                          type="submit"
                                          className="btn btn-success"
                                          id="add-btn"
                                        >
                                          Tambah product
                                        </button>
                                      </div>
                                    </ModalFooter>
                                  </form>
                                </Modal>
                              </>
                            )}
                        </>
                      ) : (
                        <div>Getting the location data&hellip; </div>
                      )}
                    </>
                  ) : (
                    <div className="alert alert-primary" role="alert">
                      Input survey telah ditutup setelah jam {config?.time_end}{" "}
                      WIB, silahkan coba kembali besok setelah jam{" "}
                      {config?.time_start} WIB
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateSurvey;
