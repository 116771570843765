import React, {useEffect, Measurementef, useState} from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter, Label, Input, FormFeedback
} from 'reactstrap';

import BreadCrumb from '../../../Components/Common/BreadCrumb';
import {useFormik} from "formik";
import * as Yup from "yup";
import DataTable from "react-data-table-component";
import {axios} from "../../../helpers/axios";
import Swal from "sweetalert2";

const Company = (props) => {
    document.title="Company | Surveyor app";
    const [modal_list, setmodal_list] = useState(false);
    const [MeasurementData, setMeasurementData] = useState([]);
    const [Data, setData] = useState([]);
    const [Paginator, setPaginator] = useState(null);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState('');

    const columns = [
        {
            name: <span className='font-weight-bold fs-13'>No.</span>,
            selector: (row, index) => index +1,
        },
        {
            name: <span className='font-weight-bold fs-13'>Name</span>,
            selector: row => row.name,
        },
        {
            name: <span className='font-weight-bold fs-13'>Action</span>,
            cell: (cell) => {
                return (
                    <>
                        <button className="btn btn-sm btn-soft-info me-2" onClick={() => editMeasurement(cell.id)}>Edit</button>
                        <button className="btn btn-sm btn-soft-danger" onClick={() => onDelete(cell.id)}>Delete</button>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        fetchMeasurement(pageSize, page, search)
    }, [page, pageSize, search]);

    useEffect(() => {
        setPage(1);
    }, [pageSize]);

    const fetchMeasurement = (pageSize, page, search) => {
        axios.get(process.env.REACT_APP_API_URL + `/v1/companies/paginated?limit=${pageSize}&page=${page}&order=desc&sort_by=id&search=${search}`).then((resp) => {
            const data = resp.data
            setData(data.items)
            setPaginator(data.paginator)
        }).catch((err) => {
            Swal.fire({
                title:"Oops",
                text:"Something error with our server",
                icon:"error",
            })
        })
    }

    const onDelete = (id) => {
        Swal.fire({
            title:"Confirmation",
            text:"Are you sure delete this record ?",
            icon:"info",
            showCancelButton:true,
        }).then((ok) => {
            if (ok.isConfirmed){
                //delete
                axios.delete(process.env.REACT_APP_API_URL + `/v1/companies/${id}`).then((resp) => {
                    fetchMeasurement(pageSize, page, search)
                }).catch((err) => {
                    Swal.fire({
                        title:"Oops",
                        text:"Something error with our server",
                        icon:"error",
                    })
                })
            }
        })
    }

    const editMeasurement = async (id) => {
        const {data} = await axios.get(process.env.REACT_APP_API_URL + `/v1/companies/${id}`).catch((err) => {
            Swal.fire({
                title:"Oops",
                text:"Something error with our server",
                icon:"error",
            })
        })
        await validation.setValues({
            id: id,
            name: data.name
        })

        ShowCloseModal()
    }

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            id: MeasurementData.id || '',
            name: MeasurementData.name || '',
        },
        validationSchema: Yup.object({
            id: Yup.string().nullable(),
            name: Yup.string().required("Please Enter name"),
        }),
        onSubmit: async (values) => {
            const payload = {
                name:values.name,
            }
            if (values.id === undefined || values.id === ""){
                const {data} = await axios.post(process.env.REACT_APP_API_URL + `/v1/companies`, payload)
                if (data === "ok"){
                    Swal.fire({
                        title:"Success",
                        text:"Success create Company",
                        icon:"success",
                    }).then((resp) => {
                        fetchMeasurement(pageSize, page, search)
                    })
                }
            }else{
                const {data} = await axios.put(process.env.REACT_APP_API_URL + `/v1/companies/${values.id}`, payload)
                if (data === "ok"){
                    Swal.fire({
                        title:"Success",
                        text:"Success update Company",
                        icon:"success",
                    }).then((resp) => {
                        fetchMeasurement(pageSize, page, search)
                    })
                }
            }
            ShowCloseModal()
        }
    });

    function ShowCloseModal() {
        setmodal_list(!modal_list);
    }
    function ShowCreateForm() {
        validation.resetForm()
        setmodal_list(!modal_list);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Company" pageTitle="Company" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <Row className="align-items-center">
                                        <Col>
                                            <h4 className="card-title mb-0 flex-grow-1">Data Company</h4>
                                        </Col>

                                        <Col className="col-sm-auto">
                                            <div className="d-flex gap-1">
                                                <Button onClick={() => ShowCreateForm()} color="success" className="add-btn" id="create-btn"><i className="ri-add-line align-bottom me-1"></i> Add</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardHeader>

                                <CardBody>
                                    <DataTable
                                        columns={columns}
                                        data={Data}
                                        pagination
                                        paginationServer={true}
                                        paginationTotalRows={Paginator !== null ? Paginator.total_item : 0}
                                        onChangePage={(page) => setPage(page)}
                                        onChangeRowsPerPage={(e) => setPageSize(e)}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal isOpen={modal_list} toggle={() => { ShowCreateForm(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { ShowCreateForm(); }}>{validation.values.id ? "Edit" : "Add"} Company </ModalHeader>
                <form  className="tablelist-form wa" onSubmit={validation.handleSubmit}>
                    <ModalBody>
                        <div className="mb-3">
                            <Label htmlFor="name" className="form-label">name</Label>
                            <Input
                                name="name"
                                className="form-control"
                                placeholder="Enter name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                    validation.touched.name && validation.errors.name ? true : false
                                }
                            />
                            {validation.touched.name && validation.errors.name ? (
                                <div className="text-danger text-sm">{validation.errors.name}</div>
                            ) : null}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Close</button>
                            <button type="submit" className="btn btn-success" id="add-btn">{validation.values.id ? "Edit" : "Add"} Company</button>
                        </div>
                    </ModalFooter>
                </form>
            </Modal>
        </React.Fragment>
    );
}

export default Company




