import React from "react";
import { Navigate } from "react-router-dom";

import User from "../pages/Main/User/User";
import Measurement from "../pages/Main/Measurement/Measurement";
import Category1 from "../pages/Main/Category1/Category1";
import Company from "../pages/Main/Company/Company";
import Brand from "../pages/Main/Brand/Brand";
import Category2 from "../pages/Main/Category2/Category2";
import Product from "../pages/Main/Product/Product";
import Promo from "../pages/Main/Promo/Promo";
import Survey from "../pages/Main/Survey/Survey";
import CreateSurvey from "../pages/Main/Survey/CreateSurvey";
import WebConfig from "../pages/Main/Config/Config";
import DashboardEcommerce from "../pages/DashboardEcommerce";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

const authProtectedRoutes = [
  // { path: "/dashboard-analytics", component: <DashboardAnalytics /> },
  // { path: "/dashboard-crm", component: <DashboardCrm /> },
  { path: "/dashboard", component: <DashboardEcommerce /> },
  { path: "/index", component: <DashboardEcommerce /> },
  { path: "/users", component: <User/> },
  { path: "/measurements", component: <Measurement/> },
  { path: "/lighting", component: <Category1/> },
  { path: "/unit3d", component: <Category2/> },
  { path: "/companies", component: <Company/> },
  { path: "/brands", component: <Brand/> },
  { path: "/products", component: <Product/> },
  { path: "/promo", component: <Promo/> },
  { path: "/survey", component: <Survey/> },
  { path: "/survey/create", component: <CreateSurvey/> },
  { path: "/config", component: <WebConfig/> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  // { path: "/forgot-password", component: <ForgetPasswordPage /> },
  // { path: "/register", component: <Register /> },
];

export { authProtectedRoutes, publicRoutes };